import React from 'react';

import PageHeader from 'components/Headers/PageHeader';
import CourtListTable from 'views/pages/court/CourtListTable';

const Court = props => {
	return (
		<>
			<PageHeader name="Court List" parentName="Hub" />
			<CourtListTable user={props.user} baseRoute={props.baseRoute} />
		</>
	);
};

export default Court;
