import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { useParams } from 'react-router-dom';
import { Card, Container, Row, Col } from 'reactstrap';

import PageHeader from 'components/Headers/PageHeader';
import { Endpoints } from 'config/endpoints';
import { iframeOptions, iframeStyles } from 'config/iframes';

const AddNewCase = props => {
	const params = useParams();

	const userEmail = props.user.attributes ? props.user.attributes.email : props.user.username.replace("onelogin_", "");
	const urlLink = {
		'yp': `${Endpoints.NEW_CASE_YP}?ClientID=${params.id}&userEmail=${userEmail}`,
		'staff': `${Endpoints.NEW_CASE_STAFF}?ClientID=${params.id}&userEmail=${userEmail}`,
	};

	return (
		<>
			<PageHeader name="New Case Referral" parentName="Hub" />

			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card style={{ height: '76vh', background: '#F3F3F3' }}>
							<ReactIframeResizer
								iframeResizerOptions={iframeOptions}
								iframeResizerUrl={false}
								src={urlLink[params.slug]}
								style={iframeStyles}
							></ReactIframeResizer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	)
};

export default AddNewCase;
