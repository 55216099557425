import React, { useState } from 'react';
import { Collapse, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';

import ProfileRowGroup from 'views/pages/profile/ProfileRowGroup.js';

const ProfileCard = props => {
	const [collapsed, setCollapsed] = useState(true);
	const handleCollapse = () => setCollapsed(state => !state);
	return (
		<div className="accordion">
			<Card>
				<CardHeader onClick={handleCollapse} aria-expanded={!collapsed}>
					<Row className="align-items-center">
						<Col xs="9">
							<h5 className="h3 mb-0">{props.data.title}</h5>
						</Col>
						<Col className="text-right" xs="4"></Col>
					</Row>
				</CardHeader>

				<Collapse isOpen={!collapsed}>
					<CardBody>
						<Row>
							{props.data.rowGroup.rows.length > 0 ? (
								<ProfileRowGroup data={props.data.rowGroup} />
							) : (
								<>
									<Col xs="8">
										<p className="text-m mt-1 mb-0">No available data</p>
									</Col>
									<Col className="text-right" xs="4"></Col>
								</>
							)}
						</Row>
					</CardBody>
				</Collapse>
			</Card>
		</div>
	);
};

export default ProfileCard;
