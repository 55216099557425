import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Card, Container, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { titleCase } from 'utils/stringFormatter';

import ProfileHeader from 'components/Headers/ProfileHeader';
import Loading from 'components/General/Loading';
import { handleRedirect } from 'utils/handles';
import secureAPI from 'utils/secureAPI';

const pagination = paginationFactory({
	page: 1,
	alwaysShowAllBtns: true,
	showTotal: true,
	withFirstAndLast: false,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} results
		</span>
	),
	sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
		<div className="dataTables_length" id="datatable-basic_length">
			<label>
				Show
				{
					<select
						name="datatable-basic_length"
						aria-controls="datatable-basic"
						className="form-control form-control-sm"
						onChange={e => onSizePerPageChange(e.target.value)}
					>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="30">30</option>
						<option value="40">40</option>
						<option value="50">50</option>
					</select>
				}
				entries.
			</label>
		</div>
	),
});

const { SearchBar } = Search;

// let qualityFilter;

const YPVerification = props => {
	const [alert, setAlert] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(true);
	const tableRef = useRef();
	const params = useParams();

	const NoDataIndication = () => (
		<Container fluid className="adapt">
			<Row>
				<Col className="text-center m-6">{loading ? <Loading /> : <div>No data available</div>}</Col>
			</Row>
		</Container>
	);

	let history = useHistory();
	let location = useLocation();

	const verifyFormatter = (cell, row) => {
		return (
			<Button
				color="primary"
				size="sm"
				type="button"
				className="mb-1"
				onClick={e =>
					handleRedirect(
						e,
						{
							history: history,
							pathid: 'verifyyp',
							baseRoute: props.baseRoute,
							parentName: 'Likely Matches',
							parentUrl: location.pathname,
						},
						[row['ClientID_Court']],
					)
				}
			>
				<span>
					<i className="fas fa-check-circle" style={{ margin: '0 10px 0 0' }}></i>
					Verify
				</span>
			</Button>
		);
	};

	const nameFormatter = (cell, row) => {
		return `${titleCase(row['FirstNameEJI'])} ${titleCase(row['LastNameEJI'])}`;
	};

	const courtNameFormatter = (cell, row) => {
		return `${titleCase(row['FirstNameCourt'])} ${titleCase(row['LastNameCourt'])}`;
	};

	const courtGenderFormatter = (cell, row) => {
		switch (cell) {
			case 'M':
				return 'Male';

			case 'F':
				return 'Female';

			default:
				return cell;
		}
	};

	useEffect(() => {
		let canSet = true;
		secureAPI(
			{
				endpoint: 'YP_VERIFICATION',
				method: 'POST',
			},
			{
				responseType: 'json',
			},
		)
			.then(response => {
				if (canSet) {
					response.data.map(el => (el['FullNameEJI'] = `${el['FirstNameEJI']} ${el['LastNameEJI']}`));
					response.data.map(el => (el['FullNameCourt'] = `${el['FirstNameCourt']} ${el['LastNameCourt']}`));
					setDataTable(response.data.filter((el) => el['Verification_Status'] === "waiting for verification"));
					setLoading(false);
				}
			})
			.catch(error => {
				console.log(error);
			});

		return () => (canSet = false);
	}, []);

	const copyToClipboardAsTable = el => {
		var body = document.body,
			range,
			sel;
		if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			sel.removeAllRanges();
			try {
				range.selectNodeContents(el);
				sel.addRange(range);
			} catch (e) {
				range.selectNode(el);
				sel.addRange(range);
			}
			document.execCommand('copy');
		} else if (body.createTextRange) {
			range = body.createTextRange();
			range.moveToElementText(el);
			range.select();
			range.execCommand('Copy');
		}
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '0px' }}
				title="Good job!"
				onConfirm={() => setAlert([])}
				onCancel={() => setAlert([])}
				confirmBtnBsStyle="info"
				btnSize=""
			>
				Copied to clipboard!
			</ReactBSAlert>,
		);
	};

	const defaultSortedBy = [
		{
			dataField: 'Verification_Status',
			order: 'desc',
		},
	];

	return (
		<>
			{alert}

			<ProfileHeader
				title="Verification Table"
				name="Likely Matches"
				parentName="Young Person Verification"
				parentLink={`${props.baseRoute}/young-person-verification`}
				baseRoute={props.baseRoute}
			/>

			<Container className="court-list-table mt--6" fluid>
				<Row>
					<div className="col">
						<Card>
							<ToolkitProvider
								data={dataTable}
								keyField="ClientID_Court"
								search={{
									searchFormatted: true,
									defaultSearch: params.id,
								}}
								columns={[
									{
										dataField: '',
										text: 'Action',
										sort: false,
										hidden: props.user.attributes['custom:eji_role'] === 'admin' ? false : true,
										formatter: verifyFormatter,
									},
									{
										dataField: 'ClientID',
										text: 'Client ID',
										sort: true,
									},
									{
										dataField: 'FirstNameEJI',
										text: 'Name (EJI database)',
										sort: true,
										formatter: nameFormatter,
									},
									{
										dataField: 'GenderEJI',
										text: 'Gender (EJI database)',
										sort: true,
									},
									{
										dataField: 'DOB EJI',
										text: 'DOB (EJI database)',
										sort: true,
									},
									{
										dataField: 'SchoolEJI',
										text: 'Last Attended (EJI database)',
										sort: true,
									},
									{
										dataField: 'ClientID_Court',
										text: 'Court ID (Courtlist)',
										sort: true,
									},
									{
										dataField: 'FirstNameCourt',
										text: 'Name (Courtlist)',
										sort: true,
										formatter: courtNameFormatter,
									},
									{
										dataField: 'GenderCourt',
										text: 'Gender (Courtlist)',
										sort: true,
										formatter: courtGenderFormatter,
									},
									{
										dataField: 'DOB Court',
										text: 'DOB (Courtlist)',
										sort: true,
									},
									{
										dataField: 'Verification_Status',
										text: 'Verification status',
										sort: true,
										formatter: cell => titleCase(cell.trim()),
									},
									{
										dataField: 'LastNameEJI',
										text: '',
										hidden: true,
									},
									{
										dataField: 'LastNameCourt',
										text: '',
										hidden: true,
									},
									{
										dataField: 'FullNameEJI',
										text: '',
										hidden: true,
									},
									{
										dataField: 'FullNameCourt',
										text: '',
										hidden: true,
									},
								]}
							>
								{props => {
									return (
										<div className="py-4 table-responsive">
											<Container fluid>
												<Row>
													<Col xs={12} sm={6}>
														<Button
															className="buttons-copy buttons-html5"
															color="default"
															size="sm"
															id="copy-tooltip"
															onClick={() =>
																copyToClipboardAsTable(
																	document.getElementById('court-list-table'),
																)
															}
														>
															<span>
																<i
																	className="fas fa-copy"
																	style={{ margin: '0 10px 0 0' }}
																></i>
																Copy
															</span>
														</Button>
														<ReactToPrint
															trigger={() => (
																<Button
																	color="default"
																	size="sm"
																	className="buttons-copy buttons-html5"
																	id="print-tooltip"
																>
																	<span>
																		<i
																			className="fas fa-print"
																			style={{ margin: '0 10px 0 0' }}
																		></i>
																		Print
																	</span>
																</Button>
															)}
															content={() => tableRef.current}
														/>
														<UncontrolledTooltip placement="top" target="print-tooltip">
															This will open a print page with the visible rows of the
															table.
														</UncontrolledTooltip>
														<UncontrolledTooltip placement="top" target="copy-tooltip">
															This will copy to your clipboard the visible rows of the
															table.
														</UncontrolledTooltip>
													</Col>
													<Col xs={12} sm={6}>
														<div
															id="datatable-basic_filter"
															className="dataTables_filter pb-1 float-right"
														>
															<label>
																Search:
																<SearchBar
																	className="form-control-sm"
																	placeholder=""
																	{...props.searchProps}
																/>
															</label>
														</div>
													</Col>
												</Row>
											</Container>
											<BootstrapTable
												id="court-list-table"
												ref={tableRef}
												bootstrap4={true}
												bordered={false}
												pagination={pagination}
												filter={filterFactory()}
												noDataIndication={() => <NoDataIndication />}
												defaultSorted={defaultSortedBy}
												{...props.baseProps}
											/>
										</div>
									);
								}}
							</ToolkitProvider>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default YPVerification;
