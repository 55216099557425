import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { useParams } from 'react-router-dom';
import { Card, Container, Row, Col } from 'reactstrap';

import ProfileHeader from 'components/Headers/ProfileHeader';
import { iframeOptions, iframeStyles } from 'config/iframes';
import { Reports } from 'config/reports';

const Reporting = props => {
	const params = useParams();
	const report = Reports.find(el => el.id === params.id)

	return report ? (
		<>
			<ProfileHeader
				title="Report"
				name={report.title}
				parentName="Reporting List"
				parentLink={`${props.baseRoute}/reporting-list`}
				baseRoute={props.baseRoute}
			/>

			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card style={{ height: '76vh', background: '#F3F3F3' }}>
							<ReactIframeResizer
								iframeResizerOptions={iframeOptions}
								iframeResizerUrl={false}
								src={report.url}
								style={iframeStyles}
							/>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<ProfileHeader
				title="Report"
				name="No Report"
				parentName="Reporting List"
				parentLink={`${props.baseRoute}/reporting-list`}
				baseRoute={props.baseRoute}
			/>

			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card>
							<p style={{margin: "1rem"}}>Invalid report</p>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Reporting;
