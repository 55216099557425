import React from 'react';

import PageHeader from 'components/Headers/PageHeader';
import CaseListTable from 'views/pages/cases/CaseListTable';

const Case = props => {
	return (
		<>
			<PageHeader name="Case List" parentName="Hub" />
			<CaseListTable user={props.user} baseRoute={props.baseRoute} />
		</>
	);
};

export default Case;
