import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardHeader, CardBody, Container, Row, Col, Modal } from 'reactstrap';
import { formatDate } from 'utils/dateFormatter';

import ProfileHeader from 'components/Headers/ProfileHeader';
import ProfileCard from 'views/pages/profile/ProfileCard';
import CourtProfileTopCard from 'views/pages/court/CourtProfileTopCard';
import Loading from 'components/General/Loading';
import { formatDateTime } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';

const CourtProfile = props => {
	const [court, setCourt] = useState({});
	const [advice, setAdvice] = useState([]);
	const [school, setSchool] = useState([]);
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(true);
	const params = useParams();

	useEffect(() => {
		// try to fix memory leak by set mounting
		let canSet = true;
		secureAPI(
			{
				endpoint: 'COURT_PROFILE',
				method: 'POST',
			},
			{
				CLIENTID_MAP: params.id,
				CLIENTID_COURT: params.id,
				responseType: 'json',
			},
		)
			.then(response => {
				if (canSet) {
					setCourt(response.data.body.court);
					setAdvice(response.data.body.advice.filter(el => el['Advice']));
					setSchool(response.data.body.school);
					setName(response.data.body.court['AFirstName'] + ' ' + response.data.body.court['ALastName']);
					setLoading(false);
				}
			})
			.catch(error => {
				console.log(error);
			});

		return () => (canSet = false);
	}, [params.id]);

	const courtInfo = {
		title: 'Court Hearing Details',
		link: '',
		colSize: 14,
		// only render rows with non-null values
		rowGroup: {
			colSize: 12,
			textWidth: 6,
			dataWidth: 6,
			rows: [
				{ name: 'Courtroom', data: court['CourtroomN'] },
				{ name: 'Hearing Date', data: court['HearingDate'] },
				{ name: 'Hearing Time', data: court['HearingTime'] },
				{ name: 'Hearing Type', data: court['HearingType'] },
				{ name: 'Agency', data: court['Agency'] },
				{ name: 'Barrister/Solicitor', data: court['BS'] },
				{ name: 'ADJ Request', data: court['ADJReq'] },
				{ name: 'Custody', data: court['Custody'] },
				{ name: 'INFT/RESP', data: court['InftResp'] },
				{ name: 'Part HRD', data: court['PartHRD'] },
			].filter(row => row.data),
		},
	};

	const attendance = val => (val === null || val === undefined ? null : `${val}`);

	const schoolInfo = {
		title: 'Enrolment Details',
		link: '',
		colSize: 14,
		// only render rows with non-null values
		rowGroup: {
			colSize: 12,
			textWidth: 6,
			dataWidth: 6,
			rows: [
				{ name: 'Current Enrolment', data: school['Enrolment1'] },
				{ name: 'Current Entry Date', data: formatDate(school['EnrolmentDate1']) },
				{ name: 'Current Exit Date', data: formatDate(school['ExitDate1']) },
				{ name: 'Previous Enrolment', data: school['Enrolment2'] },
				{ name: 'Previous Entry Date', data: formatDate(school['EnrolmentDate2']) },
				{ name: 'Previous Exit Date', data: formatDate(school['ExitDate2']) },
				{ name: 'Previous Term 1 Attendance', data: attendance(school['Enrolment2AttendanceTerm1']) },
				{ name: 'Previous Term 2 Attendance', data: attendance(school['Enrolment2AttendanceTerm2']) },
				{ name: 'Previous Term 3 Attendance', data: attendance(school['Enrolment2AttendanceTerm3']) },
				{ name: 'Previous Term 4 Attendance', data: attendance(school['Enrolment2AttendanceTerm4']) },
				{ name: 'Total Enrolment Number', data: school['TotalEnrolments'] },
				{ name: 'Date Last Updated', data: formatDate(school['LastUpdated']) },
			].filter(row => row.data),
		},
	};

	
	const attendanceInfo = {
		title: 'Enrolment Attendance Details',
		link: '',
		colSize: 14,
		rowGroup: {
			colSize: 12,
			textWidth: 6,
			dataWidth: 6,
			rows: [
				{ name: 'Attendance Data Source', data: school['AttendanceDataSource'] },

				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm1Year']} Attendance `, data:  ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm1']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm1']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm1']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm1']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm1']) },

				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm2Year']} Attendance`, data: ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm2']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm2']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm2']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm2']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm2']) },

				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm3Year']} Attendance`, data: ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm3']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm3']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm3']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm3']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm3']) },
				
				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm4Year']} Attendance`, data: ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm4']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm4']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm4']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm4']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm4']) },
			].filter(row => row.data),
		},
	};

	return !loading ? (
		<>
			<ProfileHeader
				title="Court Profile"
				name={name}
				parentName="Court List"
				parentLink={`${props.baseRoute}/court-list`}
				baseRoute={props.baseRoute}
			/>

			<Container className="mt--6" fluid>
				<CourtProfileTopCard data={court} user={props.user} baseRoute={props.baseRoute} />
				<Row className="card-wrapper">
					<Col lg="7">
						<Card>
							<CardHeader>
								<Row className="align-items-center">
									<Col xs="8">
										<h5 className="h3 mb-0">Brief Advice</h5>
									</Col>
									<Col className="text-right" xs="4"></Col>
								</Row>
							</CardHeader>

							<CardBody>
								{advice.length > 0 ? (
									advice.map((el, index) => (
										<div
											className="timeline timeline-one-side"
											data-timeline-axis-style="dashed"
											data-timeline-content="axis"
											key={index}
										>
											<div className="timeline-block">
												<span className="timeline-step badge-success">
													<i className="ni ni-bell-55" />
												</span>
												<div className="timeline-content">
													<small className="text-muted font-weight-bold">
														{formatDateTime(new Date(el.AdviceDT))}
													</small>
													<p className="text-sm mt-1 mb-0">{el.Advice}</p>
												</div>
											</div>
										</div>
									))
								) : (
									<p className="text-m mt-1 mb-0">No advice on file for {name}</p>
								)}
							</CardBody>
						</Card>
					</Col>
					<Col lg="5">
						<ProfileCard data={courtInfo}></ProfileCard>
						<ProfileCard data={schoolInfo}></ProfileCard>
						<ProfileCard data={attendanceInfo}></ProfileCard>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Court Profile ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default CourtProfile;
