import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal, TabContent, TabPane } from 'reactstrap';

import ProfileHeader from 'components/Headers/ProfileHeader';
import ProfileTopCard from 'views/pages/profile/ProfileTopCard';
import ProfileCard from 'views/pages/profile/ProfileCard';
import KnownProfile from 'views/pages/court/KnownProfile';
import Loading from 'components/General/Loading';
import secureAPI from 'utils/secureAPI';

const Profile = props => {
  const [data, setData] = useState({});
  const [name, setName] = useState('');
  const [tabs, setTab] = useState('profile');
  const [loading, setLoading] = useState(true);
  const params = useParams();
  useEffect(() => {
    let canSet = true;
    secureAPI(
      {
        endpoint: 'PROFILE',
        method: 'POST',
      },
      {
        ReferralID: params.id,
        responseType: 'json',
      },
    )
      .then(response => {
        if (canSet) {
          setData(response.data.profile);
          setName(response.data.profile['First name'] + ' ' + response.data.profile['Last name']);
          setLoading(false);
        }
      })
      .catch(error => {
        console.log(error);
      });

    return () => (canSet = false);
  }, [params.id]);

  const dgConsent = {
    title: 'Consent',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: deriveConsent(data),
    },
  };

  const dgReferrerDetails = {
    title: 'Referrer Details',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'Name of referrer', data: data['Referrer - name'] },
        { name: 'Primary contact number', data: data['Referrer - phone'] },
        { name: 'Email address', data: data['Referrer - email'] },
        { name: 'Organisation', data: data['Referrer - organisation'] },
        { name: 'Job Title', data: data['Referrer - job title'] },
        { name: 'Relationship to young person', data: data['Referrer - relationship to YP'] },
        { name: 'Source of referral', data: data['Referral source'] },
        { name: 'Referral source (if other)', data: data['Referral source (if other)'] },
      ],
    },
  };

  const dgYPReferred = {
    title: 'Young Person Key Information',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'First Name', data: data['First name'] },
        { name: 'Last name', data: data['Last name'] },
        { name: 'Preferred name', data: data['Preferred name'] },
        { name: 'Date of birth', data: data['Date of birth'] },
        { name: 'Date of birth (post court)', data: data['Date of birth_post_court'] }, //updateform
        { name: 'Address 1', data: data['Address 1'] },
        { name: 'Address 2', data: data['Address 2'] },
        { name: 'Suburb', data: data['Suburb'] },
        { name: 'State', data: data['State'] }, //updateform
        { name: 'Postcode', data: data['Postcode'] },
        { name: 'Contact number', data: data['Contact number'] },
        { name: 'Gender identity', data: data['Gender identity'] },
        { name: 'Gender identity (if other)', data: data['Gender identity (if other)'] },
        { name: 'Gender pronoun', data: data['Gender pronoun'] },
        { name: 'Gender pronoun (if other)', data: data['Gender pronoun (if other)'] },
        { name: 'Court name', data: data['Court name'] },
        { name: 'Court name (if other)', data: data['Court name (if other)'] },
        { name: 'Country of birth', data: data['Country of birth'] },
        { name: 'Ethnicity', data: data['Ethnicity'] },
        { name: 'Strengths, skills, interests', data: data['Strengths, skills, interests'] }, //updateform
        { name: 'Aspirations and goals', data: data['Aspirations and goals'] }, //updateform
      ],
    },
  };

  const dgYPDemographic = {
    title: 'Young Person Demographic Information',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'Country of birth', data: data['Country of birth'] },
        { name: 'Ethnicity', data: data['Ethnicity'] },
        { name: 'English first language', data: data['English first language'] },
        { name: 'Interpreter required', data: data['Interpreter required'] },
        { name: 'Main language at home', data: data['Main language'] },
        { name: 'ATSI', data: data['ATSI'] },
        { name: 'Mob', data: data['Mob'] }, //updateform
        { name: 'Mob interest', data: data['Mob interest'] }, //updateform
        { name: 'Disability', data: data['Disability'] },
        { name: 'PSD funding', data: data['PSD funding'] }, //updateform
        { name: 'Disability type', data: data['Disability type'] },
        { name: 'Disability type (if other)', data: data['Disability type (if other)'] },
        { name: 'Remand', data: data['Remand'] },
        { name: 'Remand (if yes)', data: data['Remand (if yes)'] },
        { name: 'Living arrangements', data: data['Living arrangements'] },
        { name: 'Living arrangements (if other)', data: data['Living arrangements (if other)'] },
        { name: 'Other demographic info', data: data['Other demographic info'] },
      ],
    },
  };

  const dgPGC = {
    title: 'Parent/Guardian/Carer (PGC) Details',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'Over age of 17', data: data['Over age of 17'] },
        { name: 'Primary: First name', data: data['PGC primary - first name'] },
        { name: 'Primary: Surname', data: data['PGC primary - surname'] },
        { name: 'Primary: Relationship', data: data['PGC primary - relationship'] },
        { name: 'Primary: Home phone', data: data['PGC primary - home phone'] },
        { name: 'Primary: Mobile phone', data: data['PGC primary - mobile phone'] },
        { name: 'Primary: Email', data: data['PGC primary - email'] },
        { name: 'Primary: Supportive/aware', data: data['PGC primary - supportive/aware'] },
        { name: 'Secondary: First name', data: data['PGC secondary - first name'] },
        { name: 'Secondary: Surname', data: data['PGC secondary - surname'] },
        { name: 'Secondary: Relationship', data: data['PGC secondary - relationship'] },
        { name: 'Secondary: Home phone', data: data['PGC secondary - home phone'] },
        { name: 'Secondary: Mobile phone', data: data['PGC secondary - mobile phone'] },
        { name: 'Secondary: Email', data: data['PGC secondary - email'] },
      ],
    },
  };

  const dgEducation = {
    title: 'Education Details',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'Engagement at the time of EJI program', data: data['YP engage/enroll EJI time'] }, // new added filed
        { name: 'School', data: data['School'] },
        { name: 'Campus', data: data['Campus'] },
        { name: 'School post court', data: data['School_post_court'] }, //updateform
        { name: 'Campus post court', data: data['Campus_post_court'] }, //updateform
        { name: 'School (if other)', data: data['School (if other)'] },
        { name: 'Enrolment status pre court', data: data['Enrolment status'] }, //updateform
        { name: 'Enrolment status post court', data: data['Enrolment status_post_court'] }, //updateform
        { name: 'Dual enrolment', data: data['Dual enrolment'] },
        { name: 'School (dual enrolment)', data: data['School (dual enrolment)'] },
        { name: 'Campus (dual enrolment)', data: data['Campus (dual enrolment)'] },
        { name: 'Enrolment status - dual enrolment', data: data['Enrolment status - dual enrolment'] }, //updateform
        {
          name: 'Number of education settings enrolled',
          data: data['Number of education settings enrolled in'],
        }, //updateform
        { name: 'Year level', data: data['Year level'] },
        { name: 'Year level status', data: data['Year level status'] },
        { name: 'Attendance rate', data: data['Attendance rate'] },
        { name: 'Last time attended school', data: data['Last time attended school'] },
        { name: '30 days absent in last 12 months', data: data['30 days absent in last 12 months'] }, //updateform
        {
          name: 'Number of absent days since case opened',
          data: data['Number of absent days since case opened'],
        }, //updateform
        { name: 'Absence data collected from', data: data['Absence data collected froom'] }, //updateform

        { name: 'School contact - first name', data: data['School contact - first name'] },
        { name: 'School contact - surname', data: data['School contact - surname'] },
        { name: 'School contact - work phone', data: data['School contact - work phone'] },
        { name: 'School contact - mobile phone', data: data['School contact - mobile phone'] },
        { name: 'School contact - email', data: data['School contact - email'] },
        { name: 'School contact - job title', data: data['School contact - job title'] },
        { name: 'Desired pathway', data: data['Desired pathway'] },
        { name: 'Desired school/education setting', data: data['Desired school/education setting'] },
        { name: 'Concerns impacting education', data: data['Concerns impacting education'] },
        {
          name: 'Concerns impacting education (if other)',
          data: data['Concerns impacting education (if other)'],
        },
        { name: 'Other program referrals', data: data['Other program referrals'] },
        { name: 'Other program referrals (if other)', data: data['Other program referrals (if other)'] },
        { name: 'Other education info', data: data['Other education info'] },
      ],
    },
  };

  const dgFurtherInfo = {
    title: 'Further Information',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'Safety concerns', data: data['Safety concerns'] },
        { name: 'Other service 1 - name', data: data['Other service 1 - name'] },
        { name: 'Other service 1 - contact number', data: data['Other service 1 - contact number'] },
        { name: 'Other service 1 - Email address', data: data['Other service 1 - Email address'] },
        { name: 'Other service 1 - organisation', data: data['Other service 1 - organisation'] },
        { name: 'Other service 1 - job title', data: data['Other service 1 - job title'] },
        { name: 'Other service 1 - relationship to YP', data: data['Other service 1 - relationship to YP'] },
        { name: 'Add 2nd contact', data: data['Add 2nd contact'] },
        { name: 'Other service 2 - name', data: data['Other service 2 - name'] },
        { name: 'Other service 2 - contact number', data: data['Other service 2 - contact number'] },
        { name: 'Other service 2 - Email address', data: data['Other service 2 - Email address'] },
        { name: 'Other service 2 - organisation', data: data['Other service 2 - organisation'] },
        { name: 'Other service 2 - job title', data: data['Other service 2 - job title'] },
        { name: 'Other service 2 - relationship to YP', data: data['Other service 2 - relationship to YP'] },
        { name: 'Add 3rd contact', data: data['Add 3rd contact'] },
        { name: 'Other service 3 - name', data: data['Other service 3 - name'] },
        { name: 'Other service 3 - contact number', data: data['Other service 3 - contact number'] },
        { name: 'Other service 3 - Email address', data: data['Other service 3 - Email address'] },
        { name: 'Other service 3 - organisation', data: data['Other service 3 - organisation'] },
        { name: 'Other service 3 - job title', data: data['Other service 3 - job title'] },
        { name: 'Other service 3 - relationship to YP', data: data['Other service 3 - relationship to YP'] },
        { name: 'Add 4th contact', data: data['Add 4th contact'] },
        { name: 'Other service 4 - name', data: data['Other service 4 - name'] },
        { name: 'Other service 4 - contact number', data: data['Other service 4 - contact number'] },
        { name: 'Other service 4 - Email address', data: data['Other service 4 - Email address'] },
        { name: 'Other service 4 - organisation', data: data['Other service 4 - organisation'] },
        { name: 'Other service 4 - job title', data: data['Other service 4 - job title'] },
        { name: 'Other service 4 - relationship to YP', data: data['Other service 4 - relationship to YP'] },
        { name: 'Add 5th contact', data: data['Add 5th contact'] },
        { name: 'Other service 5 - name', data: data['Other service 5 - name'] },
        { name: 'Other service 5 - contact number', data: data['Other service 5 - contact number'] },
        { name: 'Other service 5 - Email address', data: data['Other service 5 - Email address'] },
        { name: 'Other service 5 - organisation', data: data['Other service 5 - organisation'] },
        { name: 'Other service 5 - job title', data: data['Other service 5 - job title'] },
        { name: 'Other service 5 - relationship to YP', data: data['Other service 5 - relationship to YP'] },
        { name: 'Add 6th contact', data: data['Add 6th contact'] },
        { name: 'Other service 6 - name', data: data['Other service 6 - name'] },
        { name: 'Other service 6 - contact number', data: data['Other service 6 - contact number'] },
        { name: 'Other service 6 - Email address', data: data['Other service 6 - Email address'] },
        { name: 'Other service 6 - organisation', data: data['Other service 6 - organisation'] },
        { name: 'Other service 6 - job title', data: data['Other service 6 - job title'] },
        { name: 'Other service 6 - relationship to YP', data: data['Other service 6 - relationship to YP'] },
      ].filter(item => item.data),
    },
  };

  // status update form  additional
  const dgPreCourt = {
    title: 'Pre Court Appearance',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'First hearing date known to EJI', data: data['First hearing date known to EJI'] },
        {
          name: 'Number of court appearances EJI is aware of since the YPs record opened',
          data: data['Number of court appearances EJI is aware of since the YPs record opened'],
        },
        {
          name: 'Number of education settings enrolled in',
          data: data['Number of education settings enrolled in'],
        },
        { name: 'Attendance information', data: data['Attendance information'] },
        { name: 'Contact type', data: data['Contact type'] },
      ],
    },
  };

  const dgServicesProvided = {
    title: 'Post Court Services',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'Outputs enrolment', data: data['Outputs enrolment'] },
        { name: 'Planned engagement setting', data: data['Planned engagement setting'] },
        { name: 'New school/provider', data: data['New school/provider'] },

        {
          name: 'Has YP engaged in education at 6 weeks since case opening',
          data: data['YP engaged in education at 6 weeks since case opening'],
        },
        {
          name: 'Has YP engaged in education at 3 months since case opening',
          data: data['YP engaged in education at 3 months since case opening'],
        },
        {
          name: 'Has YP engaged with referred employment pathway at 6 weeks since case opening',
          data: data['YP engaged with referred employment pathway at 6 weeks since case opening'],
        },
        {
          name: 'Has YP engaged with referred employment pathway at 3 months since case opening',
          data: data['YP engaged with referred employment pathway at 3 months since case opening'],
        },
        {
          name: 'YP still requiring EJI support post 3 months since case opening?',
          data: data['YP still requiring EJI support post 3 months since case opening'],
        },

        {
          name:
            'Other programs referred to or liaised with to obtain services or support for this young person',
          data: data['Referred to or liaised with other programs'],
        },
        {
          name:
            'Other programs referred to or liaised with to obtain services or support for this young person (if other)',
          data: data['Referred to or liaised with other programs (if other)'],
        },
        { name: 'Outputs for justice system support', data: data['Outputs for justice system support'] },
        {
          name: 'Outputs for justice system support (if other)',
          data: data['Outputs for justice system support (if other)'],
        },
      ],
    },
  };

  const dgCaseClosure = {
    title: 'Case Closure Information',
    link: '',
    colSize: 12,
    rowGroup: {
      colSize: 12,
      textWidth: 5,
      dataWidth: 7,
      rows: [
        { name: 'Reason for case closure', data: data['Reason for case closure'] },
        { name: 'Young Person profile up to date', data: data['YP profile up to date'] },
        { name: 'Service information update to date', data: data['Service information update to date'] },

        {
          name: 'Setting type of Young Person enrolment at the time of closure',
          data: data['Setting type of YP enrolment at the time of closure'],
        },
        {
          name: 'Education setting',
          data: data['Education setting'],
        },
        {
          name: 'Type of training, education or education pathway for the Young Person',
          data: data['Type of training, education or education pathway for the YP'],
        },
        {
          name: 'Type of training, education or education pathway for the Young Person (if other)',
          data: data['Type of training, education or education pathway for the YP (if other)'],
        },
        {
          name: 'Number of days absent YTD at case closing',
          data: data['Number of days absent YTD at case closing'],
        },

        {
          name: 'Additional information in relation to case closure',
          data: data['Additional information in relation to case closure'],
        },
        {
          name: 'Exit survey complete',
          data: data['Exit survey complete'],
        },
      ],
    },
  };

  return !loading ? (
    <>
      <ProfileHeader
        title="Case Profile"
        name={name}
        parentName="Case List"
        parentLink={`${props.baseRoute}/cases`}
        baseRoute={props.baseRoute}
      />
      <Container className="mt--6" fluid>
        <ProfileTopCard
          data={data}
          user={props.user}
          baseRoute={props.baseRoute}
          tabs={tabs}
          tabFunc={setTab}
        />

        <TabContent activeTab={tabs}>
          <TabPane tabId="profile">
            <Row className="card-wrapper">
              <Col lg="6">
                <ProfileCard data={dgYPReferred} />
                <ProfileCard data={dgReferrerDetails} />
                <ProfileCard data={dgYPDemographic} />
                <ProfileCard data={dgConsent} />
                <ProfileCard data={dgPreCourt} />
              </Col>
              <Col lg="6">
                <ProfileCard data={dgPGC} />
                <ProfileCard data={dgEducation} />
                <ProfileCard data={dgFurtherInfo} />
                <ProfileCard data={dgServicesProvided} />
                <ProfileCard data={dgCaseClosure} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="known">
            <KnownProfile component={true} />
          </TabPane>
        </TabContent>
      </Container>
    </>
  ) : (
    <>
      <Modal className="modal-dialog-centered" isOpen={loading}>
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Loading Profile ....
          </h6>
        </div>
        <div className="modal-body">
          <Container>
            <Row>
              <Col className="text-center">
                <Loading />
              </Col>
            </Row>
          </Container>
        </div>
      </Modal>
    </>
  );
};

const deriveConsent = (data) => {
  // getting rows to display consent information

  const consentProvided = (
    data['Consent obtained'] ||                         // if consent is obtained, it will be the first Yes
    data['In person YP consent (over 16)'] ||           // otherwise, check if consent is obtained from an over 16yo YP
    data['In person PGC consent (under 16)'] ||         // otherwise, check if consent is obtained from parent/guardian/carer
    (data['Verbal consent provided by'] ? "Yes" : null) // otherwise, check if consent is obtained verbally
  ) || "No"                                             // otherwise no consent has been obtained

  const methodObtained = data['Method YP consent obtained']

  let consentProvidedBy;
  if (data['Consent obtained']) {
    // if consent is obtained by KECCLO/RECCLO, this information is manually entered, including an "other"
    consentProvidedBy = (data['Consent provided by'] === 'Other') ? data['Consent provided by (if other)'] : data['Consent provided by']
  } else if (data['In person YP consent (over 16)']) {
    // if consent is provided by the YP over 16, then display this
    consentProvidedBy = "Young person (16 years and over)"
  } else {
    // if the consent is provided by someone else, either in person or verbally, display this
    consentProvidedBy = data['In person PGC consent - PGC name'] || data['Verbal consent provided by']
  }

  // display extra rows if based on the method the consent is obtained
  // TODO: display a method obtained for if a case worker obtains this without using the YP referral form
  let extraRows = []
  if (methodObtained === "Verbally") {
    extraRows = [
      { name: 'Method Young Person consent obtained', data: methodObtained },
      { name: 'Date verbal consent provided', data: data['Date verbal consent provided'] },
      { name: 'Time verbal consent provided', data: data['Time verbal consent provided'] },
      { name: 'Location verbal consent provided', data: data['Location verbal consent provided'] },
    ]
  } else if (methodObtained === "In person") {
    extraRows = [
      { name: 'Method Young Person consent obtained', data: methodObtained },
    ]
  }

  return [
    { name: 'Consent provided', data: consentProvided },
    { name: 'Consent provided by', data: consentProvidedBy },
    ...extraRows
  ]
}

export default Profile;
