import React from 'react';
// import { Amplify, Hub } from 'aws-amplify';
import { Amplify, Hub } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { AmplifyAuthenticator } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Route, Switch, Redirect } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar.js';
import Sidebar from 'components/Sidebar/Sidebar.js';
import { Endpoints } from 'config/endpoints';
import { Button, Col, Card, CardBody, CardHeader } from 'reactstrap';

import routes from 'routes';
import awsconfig from '../aws-exports';
import UseAuthState from 'hooks/useAuthState';

// allow for multiple redirect URLs in Cognito dynamically
// direct to prod if neither local, test or master
const hostIndex = authURLs => {
	var getIndexOf = searchString => authURLs.findIndex(url => url.search(searchString) > -1);
	var authIndex = getIndexOf('://' + window.location.hostname);
	return authIndex > -1 ? authURLs[authIndex] : authURLs[getIndexOf('://prod')];
};

const AUTH_URL_VALIDATOR = process.env.REACT_APP_AUTH_URL_VALIDATOR;

const updatedAwsConfig = {
	...awsconfig,
	oauth: {
		...awsconfig.oauth,
		domain: AUTH_URL_VALIDATOR,
		scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
		responseType: 'token',
		redirectSignIn: hostIndex(Endpoints.APP.split(', ')),
		redirectSignOut: hostIndex(Endpoints.APP.split(', ')),
	},
};

Amplify.configure(updatedAwsConfig);
Auth.configure(updatedAwsConfig);

const isDesktop = window.innerWidth > 1023;

class Admin extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sidenavOpen: isDesktop ? true : false,
			authState: 'loading',
			user: {},
			user_attr: {},
			user_name: '...',
			baseRoute: '',
		};

		this.logo = require('assets/img/brand/det-logo.svg');

		if (isDesktop) {
			document.body.classList.add('g-sidenav-show');
			document.body.classList.add('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-hidden');
		}
	}

	componentDidMount() {
		Hub.listen('auth', ({ payload: { event } }) => {
			switch (event) {
				case 'signIn':
					this.setState({ authState: 'signedin' });
					break;
				case 'cognitoHostedUI':
					localStorage.setItem('amplify-redirected-from-hosted-ui', false);
					break;
				default:
					// console.log(event);
					break;
			}
		});
		onAuthUIStateChange((nextAuthState, user) => {
			if (nextAuthState === AuthState.SignedIn && user) {
				this.setState({
					sidenavOpen: isDesktop ? true : false,
					authState: nextAuthState,
					user_attr: user.attributes ? user.attributes : {},
					user_name: user.attributes
						? user.attributes.given_name + ' ' + user.attributes.family_name
						: user.username.replace('onelogin_', ''),
					user: user,
					baseRoute: user.attributes ? (user.attributes['custom:eji_role'] === 'admin' ? '/admin' : '') : '',
				});
			}
			if (!user) {
				console.log('user is not signed in...');
			}
		});
	}

	componentDidUpdate(e) {
		if (e.history.pathname !== e.location.pathname) {
			document.documentElement.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
			this.refs.mainContent.scrollTop = 0;
		}
	}

	getRoutes = routes => {
		return routes.map((prop, key) => {
			const Component = prop.component;
			const attrs = this.state.user.attributes ? this.state.user.attributes : this.state.user_attr;
			if (attrs['custom:eji_role'] === 'admin') {
				return (
					<Route
						path={`${this.state.baseRoute}${prop.path}`}
						render={props => (
							<Component {...props} user={this.state.user} baseRoute={this.state.baseRoute} />
						)}
						key={key}
					/>
				);
			} else {
				if (prop.use === 'view') {
					return (
						<Route
							path={`${this.state.baseRoute}${prop.path}`}
							render={props => (
								<Component {...props} user={this.state.user} baseRoute={this.state.baseRoute} />
							)}
							key={key}
						/>
					);
				} else {
					return null;
				}
			}
		});
	};
	getBrandText = path => {
		for (let i = 0; i < routes.length; i++) {
			if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
				return routes[i].name;
			}
		}
		return 'Brand';
	};
	// toggles collapse between mini sidenav and normal
	toggleSidenav = e => {
		if (document.body.classList.contains('g-sidenav-pinned')) {
			document.body.classList.remove('g-sidenav-pinned');
			document.body.classList.add('g-sidenav-hidden');
		} else {
			document.body.classList.add('g-sidenav-pinned');
			document.body.classList.remove('g-sidenav-hidden');
		}

		this.setState((prevState, props) => ({
			sidenavOpen: !prevState.sidenavOpen,
		}));
	};
	getNavbarTheme = () => {
		return this.props.location.pathname.indexOf('admin/alternative-dashboard') === -1 ? 'dark' : 'light';
	};

	render() {
		return this.state.authState === AuthState.SignedIn && this.state.user ? (
			<>
				<AmplifyAuthenticator>
					<Sidebar
						{...this.props}
						routes={routes.map(route => {
							route['layout'] = this.state.baseRoute;
							return route;
						})}
						toggleSidenav={this.toggleSidenav}
						sidenavOpen={this.state.sidenavOpen}
						logo={{
							innerLink: '/',
							imgSrc: require('assets/img/brand/det-logo-p.png'),
							imgAlt: '...',
						}}
					/>
					<div className="main-content" ref="mainContent" onClick={this.closeSidenav}>
						<div className="page-content">
							<AdminNavbar
								{...this.props}
								user_name={this.state.user_name}
								theme={this.getNavbarTheme()}
								toggleSidenav={this.toggleSidenav}
								sidenavOpen={this.state.sidenavOpen}
								brandText={this.getBrandText(this.props.location.pathname)}
							/>
							<UseAuthState />
							<Switch>
								{this.getRoutes(routes)}
								<Redirect from="*" to={`${this.state.baseRoute}/hub`} />
							</Switch>
						</div>
					</div>
					{this.state.sidenavOpen ? (
						<div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
					) : null}
				</AmplifyAuthenticator>
			</>
		) : (
			<AmplifyAuthenticator>
				<div
					slot="sign-in"
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						flex: 1,
						height: '100vh',
						width: '100vw',
					}}
				>
					<Card style={{ width: '50%', maxWidth: '35em' }}>
						<CardHeader className="card-borderless" style={{ display: 'flex', justifyContent: 'center' }}>
							<img
								alt="VicGovDET"
								style={{
									maxWidth: '100%',
									height: 'auto',
								}}
								src={this.logo}
							/>
						</CardHeader>
						<CardBody>
							<Col>
								<Button
									block
									style={{ height: '80px' }}
									color="primary"
									onClick={() => Auth.federatedSignIn()}
								>
									Sign into EJI Portal
								</Button>
							</Col>
						</CardBody>
					</Card>
				</div>
			</AmplifyAuthenticator>
		);
	}
}

export default Admin;
