import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import TimelineCard from 'components/General/TimelineCard';
import secureAPI from 'utils/secureAPI';

const Notes = props => {
	const [notes, setNotes] = useState([]);
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(true);
	const [responded, setResponded] = useState({});
	let params = useParams();

	useEffect(() => {
		let canSet = true;
		secureAPI(
			{
				endpoint: 'PROFILE',
				method: 'POST',
			},
			{
				ReferralID: params.referral,
				responseType: 'json',
			},
		)
			.then(response => {
				if (canSet) {
					setName(response.data.profile['First name'] + ' ' + response.data.profile['Last name']);
					setResponded(r => ({ ...r, name: true }));
				}
			})
			.catch(error => {
				console.log(error);
			});

		secureAPI(
			{
				endpoint: 'NOTES',
				method: 'POST',
			},
			{
				ReferralID: params.referral,
				responseType: 'json',
			},
		)
			.then(response => {
				if (canSet) {
					setNotes(response.data);
					setResponded(r => ({ ...r, notes: true }));
				}
			})
			.catch(error => {
				console.log(error);
			});
		return () => (canSet = false);
	}, [params.referral]);

	useEffect(() => {
		let canSet = true;
		if (responded.name && responded.notes && canSet) {
			setLoading(false);
		}
		return () => (canSet = false);
	}, [responded]);

	return !loading ? (
		<>
			<ProfileUpdateHeader
				name="Notes"
				refId={params.referral}
				clientId={params.client}
				parentName={name}
				baseRoute={props.baseRoute}
			/>
			<Container className="mt--6" fluid>
				<Row>
					<Col lg="12">
						<TimelineCard
							header={{
								title: 'Notes',
							}}
							body={{
								data: notes,
								date: 'EndDate',
								target: 'NOTES',
								nodata: `No notes on file for ${name}`,
								icon: 'ni ni-bell-55',
								iconStyle: 'timeline-step badge-success',
							}}
						/>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Notes ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default Notes;
