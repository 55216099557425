import React, { useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useHistory, useParams } from 'react-router-dom';

import { Card, CardBody, CardText, CardTitle, Container, Row, Col, Button } from 'reactstrap';

import PageHeader from 'components/Headers/PageHeader';
import { Endpoints } from 'config/endpoints';

const NewCase = props => {
	const [alert, setAlert] = useState([]);
	const history = useHistory();
	const params = useParams();

	const userEmail = props.user.attributes ? props.user.attributes.email : props.user.username.replace("onelogin_", "");
	const urlLink = {
		'yp': `${Endpoints.NEW_CASE_YP}?ClientID=${params.id}&userEmail=${userEmail}`,
		'staff': `${Endpoints.NEW_CASE_STAFF}?ClientID=${params.id}&userEmail=${userEmail}`,
	};

	const hubNewCaseClick = slug => {
		history.push(`${props.baseRoute}/add-new-case/${params.id}/${slug}`);
	};

	const referralLink = slug => {
		setAlert(
			<ReactBSAlert
				info
				style={{ display: 'block', marginTop: '150px' }}
				title="Young Person EJI Referral Link"
				onConfirm={() => setAlert([])}
				confirmBtnBsStyle="info"
				btnSize=""
			>
				<div id='alert-text'>{urlLink[slug]}</div>
			</ReactBSAlert>
		)
	};


	return (
		<>
			{alert}

			<PageHeader name="New Case" parentName="Hub" />

			<Container className="mt--6" fluid>
				<Row className="card-wrapper">
					<Col lg="4">
						<Card className="card-pricing bg-gradient-orange" tag="a" onClick={() => hubNewCaseClick('yp')}>
							<CardBody>
								<Row>
									<Col className="col">
										<CardTitle tag="h5" className="h2 font-weight-bold">
											Young Person
										</CardTitle>
										<CardText>Young person completing referral</CardText>
										<button
											type="button"
											className="btn btn-primary"
											onClick={e => e.preventDefault()}
										>
											Create referral
										</button>
									</Col>
									<Col className="col-auto">
										<div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
											<i className="ni ni-single-02" />
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
					<Col lg="4">
						<Card
							className="card-pricing bg-gradient-green"
							tag="a"
							onClick={() => hubNewCaseClick('staff')}
						>
							<CardBody>
								<Row>
									<Col className="col">
										<CardTitle tag="h5" className="h2 font-weight-bold">
											Other
										</CardTitle>
										<CardText>Other person completing referral</CardText>
										<button
											type="button"
											className="btn btn-primary"
											onClick={e => e.preventDefault()}
										>
											Create referral
										</button>
									</Col>
									<Col className="col-auto">
										<div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
											<i className="ni ni-single-02" />
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</Col>
				</Row>
				<Row className="card-wrapper">
					<Col lg="4">
						<Button
							block
							color="default"
							size="lg"
							type="button"
							className="mb-1"
							onClick={() => referralLink('yp')}
						>
							Generate Young Person Referral Link
						</Button>
					</Col>
					<Col lg="4">
						<Button
							block
							color="success"
							size="lg"
							type="button"
							className="mb-1"
							onClick={() => referralLink('staff')}
						>
							Generate Other Referral Link
						</Button>
					</Col>
				</Row>
			</Container>
		</>
	)
};

export default NewCase;
