import React from 'react';
import { Col } from 'reactstrap';

import ProfileRow from 'views/pages/profile/ProfileRow.js';

const ProfileRowGroup = props => {
	return (
		<>
			<Col lg={props.data.colSize}>
				{props.data.rows.map((row, index) => (
					<ProfileRow
						key={index}
						name={row.name}
						data={row.data}
						textWidth={props.data.textWidth}
						dataWidth={props.data.dataWidth}
					/>
				))}
			</Col>
		</>
	);
};

export default ProfileRowGroup;
