import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import HubCard from 'components/Cards/HubCard';
import HubHeader from 'components/Headers/HubHeader.js';
import AdminFooter from 'components/Footers/AdminFooter.js';

const Hub = props => {
	const history = useHistory();

	const hubCaseClick = e => {
		history.push(`${props.baseRoute}/cases`);
	};
	const refListClick = e => {
		history.push(`${props.baseRoute}/court-list`);
	};
	const reportClick = e => {
		history.push(`${props.baseRoute}/reporting-list`);
	};

	const knownPersonsClick = e => {
		history.push(`${props.baseRoute}/known-persons`);
	};

	return (
		<>
			<HubHeader name="Hub" />
			<Container className="mt--6" fluid>
				<div className="card-deck">
					<HubCard
						color="bg-gradient-orange"
						onClick={hubCaseClick}
						title="Case List"
						body="Form process to refer young people to the EJI program."
						buttonText="View case list"
						icon="ni ni-briefcase-24"
					/>
					<HubCard
						color="bg-gradient-red"
						onClick={knownPersonsClick}
						title="Known Persons"
						body="Table of persons known to the EJI program workers."
						buttonText="View known persons"
						icon="ni ni-lock-circle-open"
					/>
					<HubCard
						color="bg-gradient-green"
						onClick={refListClick}
						title="Court List"
						body="Table of current referrals in the EJI program."
						buttonText="View court list"
						icon="ni ni-diamond"
					/>
				</div>
				<Row className="card-wrapper">
					<Col lg='4'>
						<div className="card-deck">
						<HubCard
							color="bg-gradient-purple"
							onClick={reportClick}
							title="Reporting"
							body="Analytical and graphical EJI program reporting suite."
							buttonText="View reports"
							icon="ni ni-sound-wave"
						/>
						</div>
					</Col>
				</Row>				
			</Container>
			<AdminFooter />
		</>
	);
};

export default Hub;
