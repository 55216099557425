import React, { useState } from 'react';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import {
	Card,
	CardHeader,
	CardBody,
	Row,
	Col,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownItem,
	DropdownMenu,
} from 'reactstrap';

import { handleRedirect, handleOptions } from 'utils/handles';

import ProfileRowGroup from 'views/pages/profile/ProfileRowGroup';
import { useHistory, useLocation } from 'react-router-dom';
import secureAPI from 'utils/secureAPI';

const KnownProfileTopCard = props => {
	const [alert, setAlert] = useState([]);
	let history = useHistory();
	let location = useLocation();

	const setBlank = val => (val ? val : '-');

	const dob = props.data['DOB']?.split('/');
	const timeStamp = dob ? new Date(dob[2], dob[1] - 1, dob[0]) : null;
	const difference = Date.now() - timeStamp;
	const currentAge = Math.floor(difference / 31557600000);

	const rg1 = {
		colSize: 5,
		textWidth: 4,
		dataWidth: 4,
		rows: [
			{ name: 'Young Person ID', data: setBlank(props.data['CLIENTID_COURT']) },
			{ name: 'Preferred Name', data: setBlank(props.data['Preferred name']) },
			{ name: 'Date of birth', data: props.data['DOB'] },
			{ name: 'Age', data: currentAge },
			{ name: 'Gender', data: props.data['Gender'] },
			{ name: 'ATSI Status', data: setBlank(props.data['ATSI']) },
		],
	};

	const rg2 = {
		colSize: 5,
		textWidth: 4,
		dataWidth: 6,
		rows: [
			{ name: 'EJI Case Status', data: setBlank(props.data['CaseStatus']) },
			{ name: 'EJI Referral ID', data: setBlank(props.data['ReferralID']) },
			{ name: 'Court Case Type', data: setBlank(props.data['CourtHearingType']) },
			{ name: 'Court Case Number', data: setBlank(props.data['CourtCaseN']) },
		],
	};

	const dynamicButton = handleOptions(
		props.data['MatchFinal'],
		history,
		props.data['CLIENTID_COURT'],
		props.data['ReferralID'],
		props.data['CaseStatus'],
		props.baseRoute,
		props.data['FirstName'] + ' ' + props.data['LastName'],
	);

	const profileOptions =
		props.user.attributes['custom:eji_role'] === 'admin' ? (
			<DropdownMenu>
				{dynamicButton}
				<DropdownItem divider />
				<DropdownItem
					onClick={e =>
						handleRedirect(
							e,
							{
								history: history,
								pathid: 'courtprofileupdate',
								baseRoute: props.baseRoute,
								parentName: `${props.data['FirstName']} ${props.data['LastName']}`,
								parentUrl: location.pathname,
							},
							['advice', props.data['CLIENTID_COURT'], props.data['CLIENTID_COURT']],
						)
					}
				>
					Add Brief Advice
				</DropdownItem>
				<DropdownItem
					onClick={e =>
						handleRedirect(
							e,
							{
								history: history,
								pathid: 'courtprofileupdate',
								baseRoute: props.baseRoute,
								parentName: `${props.data['FirstName']} ${props.data['LastName']}`,
								parentUrl: location.pathname,
							},
							['school', props.data['CLIENTID_COURT'], props.data['CLIENTID_COURT']],
						)
					}
				>
					Add School Information
				</DropdownItem>
			</DropdownMenu>
		) : (
			<DropdownMenu>{dynamicButton ? dynamicButton : null}</DropdownMenu>
		);
	return (
		<>
			{alert}

			<Row className="card-wrapper">
				<Col lg="12">
					<Card>
						<CardHeader>
							<Row className="align-items-center">
								<Col xs="8">
									<h5 className="h2 mb-0">
										{props.data['FirstName']} {props.data['LastName']}
									</h5>
								</Col>
								<Col className="text-right" xs="4">
									<UncontrolledDropdown>
										<DropdownToggle block size="m" color="default">
											<i className="fas fa-cog" style={{ margin: '0 10px 0 0' }}></i>
											Profile Options
										</DropdownToggle>
										{profileOptions}
									</UncontrolledDropdown>
								</Col>
							</Row>
						</CardHeader>

						<CardBody>
							<Row>
								<ProfileRowGroup data={rg1} />
								<ProfileRowGroup data={rg2} />
							</Row>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default KnownProfileTopCard;
