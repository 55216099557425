export const titleCase = str => {
	if (str === null) {
		return str;
	}
	str = str
		.trim()
		.toLowerCase()
		.split(' ');
	for (var i = 0; i < str.length; i++) {
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	}
	return str.join(' ');
};

// function to replace falsey values with a string replacement for formatting displays
export const setBlank = (val, replacement = '-') => (val ? val : replacement);
