import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { AmplifySignOut } from '@aws-amplify/ui-react';

import {
	Collapse,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Navbar,
	NavItem,
	Nav,
	Container,
	Col,
} from 'reactstrap';

const AdminNavbar = props => {
	return (
		<>
			<Navbar
				className={classnames(
					'navbar-top navbar-expand border-bottom',
					{ 'navbar-dark bg-info': props.theme === 'dark' },
					{ 'navbar-light bg-secondary': props.theme === 'light' },
				)}
			>
				<Container fluid>
					<Collapse navbar isOpen={true}>
						<Nav className="align-items-center ml-xl-auto" navbar>
							<NavItem className="d-xl-none">
								<div
									className={classnames(
										'pr-3 sidenav-toggler',
										{ active: props.sidenavOpen },
										{ 'sidenav-toggler-dark': props.theme === 'dark' },
									)}
									onClick={props.toggleSidenav}
								>
									<div className="sidenav-toggler-inner">
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
										<i className="sidenav-toggler-line" />
									</div>
								</div>
							</NavItem>
						</Nav>

						<Col className="pl-0">
							<h5 className="h2 mb-0 text-white">EJI Portal</h5>
						</Col>

						<Nav className="align-items-center ml-auto ml-xl-0" navbar>
							<UncontrolledDropdown nav>
								<DropdownToggle className="nav-link pr-0" color="" tag="a">
									<Media className="align-items-center">
										<span
											className="avatar avatar-sm rounded-circle"
											style={{ margin: '0 10px 0 0' }}
										>
											<Avatar name={props.user_name} size="40" round={true} color="red" />
										</span>
										<Media className="ml-2 d-none d-lg-block">
											<span className="mb-0 text-sm font-weight-bold">{props.user_name}</span>
										</Media>
									</Media>
								</DropdownToggle>
								<DropdownMenu right>
									<DropdownItem href="#" onClick={e => e.preventDefault()}>
										<span>
											<AmplifySignOut />
										</span>
									</DropdownItem>
								</DropdownMenu>
							</UncontrolledDropdown>
						</Nav>
					</Collapse>
				</Container>
			</Navbar>
		</>
	);
};

AdminNavbar.defaultProps = {
	toggleSidenav: () => {},
	sidenavOpen: false,
	theme: 'dark',
};

AdminNavbar.propTypes = {
	toggleSidenav: PropTypes.func,
	sidenavOpen: PropTypes.bool,
	theme: PropTypes.oneOf(['dark', 'light']),
};

export default AdminNavbar;
