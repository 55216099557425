const REACT_APP_TABLEAU_BASE_URL = process.env.REACT_APP_TABLEAU_BASE_URL;
const REACT_APP_TABLEAU_DASHBOARD_NAME = process.env.REACT_APP_TABLEAU_DASHBOARD_NAME;

export const Reports = [
	{
		id: '0001',
		title: 'Court List Report',
		description: 'Court list information',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/CourtListReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0002',
		title: 'Young Person Report',
		description: 'Young person case report',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/YoungPersonReport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0003',
		title: 'Cases Dashboards',
		description: 'Active referral information',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/CasesDashboard?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0004',
		title: 'Closed Cases Dashboards',
		description: 'Closed cases information',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/EJIClosedCasesDash?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0005',
		title: 'RAJAC Report',
		description: 'RAJAC Reporting',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/Rajacreport?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0006',
		title: 'Active Cases Dashboard',
		description: 'Active cases information',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/ActiveCasesDash?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0007',
		title: 'Outcomes at Monitoring Stage',
		description: 'Detailed case information at monitoring stage',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/Outcomesatmonitoringstage?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0008',
		title: 'Outcomes at Monitoring Stage - Administration',
		description: 'Cases by EJI team member at monitoring stage',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/Outstandingmonitoringupdates?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	},
	{
		id: '0009',
		title: 'EJI Program Dashboard',
		description: 'Overview of young persons EJI has supported over a specified period of time',
		url:
			REACT_APP_TABLEAU_BASE_URL + REACT_APP_TABLEAU_DASHBOARD_NAME + '/Supportdash?:showAppBanner=false&:display_count=n&:showVizHome=n&:origin=viz_share_link&:embed=true'
	}
];