import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { useLocation, useParams } from 'react-router-dom';
import { Card, Container, Row, Col } from 'reactstrap';

import ProfileHeader from 'components/Headers/ProfileHeader';
import { Endpoints } from 'config/endpoints';
import { iframeOptions, iframeStyles } from 'config/iframes';

const YPVerification = props => {
	const params = useParams();
	let location = useLocation();
	// relies on searching through the Verification table
	// this switch needs to be monitored
	let urlLink = `${Endpoints.VERIFY_YP}?ClientID_Court=${params.id}`;

	return (
		<>
			<ProfileHeader 
				title="Verification Form" 
				name="Verification Form" 
				parentName={location.state.parentName}
				parentLink={location.state.parentUrl}
				baseRoute={props.baseRoute}
			/>


			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card style={{ height: '76vh', background: '#F3F3F3' }}>
							<ReactIframeResizer
								iframeResizerOptions={iframeOptions}
								iframeResizerUrl={false}
								src={urlLink}
								style={iframeStyles}
							></ReactIframeResizer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default YPVerification;
