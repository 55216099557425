// import { format } from 'date-fns';
import moment from 'moment';

export const formatDate = (date, utc = true) => {
	let d;
	if (utc) {
		d = moment.utc(date, 'YYYY-MM-DD hh-mm-ss');
	} else {
		d = moment.utc(date, 'DD/MM/YYYY');
	}
	return date ? d.local().format('DD/MM/YYYY') : null;
};

export const formatDateTime = dateTime => {
	let d = moment.utc(dateTime, 'YYYY-MM-DD hh-mm-ss');
	return d.local().format('DD MMM YY - h:mm a');
};

export const formatShortDate = dateTime => {
	let d = moment.utc(dateTime, 'YYYY-MM-DD hh-mm-ss');
	return d.local().format('DD MMM YY');
};

export const formatLongDate = dateTime => {
	let d = moment.utc(dateTime, 'YYYY-MM-DD hh-mm-ss');
	return d.local().format('DD MMMM YYYY');
};

export const formatDateAU = date => {
	let dArray = date
		.split('/')
		.map(num => parseInt(num, 10))
		.reverse();
	dArray[1] -= 1; // Javascript months are 0-based index
	return moment(dArray);
};
