import Hub from 'views/pages/hubs/Hub';
import Profile from 'views/pages/profile/Profile';
import NewCase from 'views/pages/cases/NewCase';
import AddNewCase from 'views/pages/cases/AddNewCase';
import Case from 'views/pages/cases/Case';
import CourtList from 'views/pages/court/Court';
import CourtProfile from 'views/pages/court/CourtProfile';
import CourtProfileUpdateForm from 'views/pages/court/CourtProfileUpdateForm';
import YPVerification from 'views/pages/verification/YPVerification';
import YPNoMatch from 'views/pages/verification/YPNoMatch';
import VerifyYP from 'views/pages/verification/YPVerificationForm';
import YPVerificationList from 'views/pages/verification/YPVerificationList';
import AddUser from 'views/pages/users/AddUser';
import ProfileUpdate from 'views/pages/profile/ProfileUpdate';
import Notes from 'views/pages/profile/Notes';
import History from 'views/pages/profile/History';
import CloseCase from 'views/pages/profile/CloseCase';
import Reporting from 'views/pages/reporting/Reporting';
import ReportingList from 'views/pages/reporting/ReportingList';
import KnownsList from 'views/pages/court/KnownsList';
import KnownProfile from 'views/pages/court/KnownProfile';
import Uploads from 'views/pages/profile/Uploads';

const routes = [
	{
		id: 'hub',
		root: '/hub',
		name: 'Hub',
		icon: 'ni ni-shop text-primary',
		component: Hub,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: false,
	},
	{
		id: 'caselist',
		root: '/cases',
		name: 'Case List',
		icon: 'ni ni-briefcase-24 text-primary',
		component: Case,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: false,
	},
	{
		id: 'newcase',
		root: '/new-case',
		params: [':id'],
		name: 'New Case',
		icon: 'ni ni-single-02 text-primary',
		component: NewCase,
		layout: '/admin',
		use: 'edit',
		hiddenSideNav: true,
	},
	{
		id: 'addnewcase',
		root: '/add-new-case',
		params: [':id', ':slug'],
		name: 'New Case',
		icon: 'ni ni-single-02 text-primary',
		component: AddNewCase,
		layout: '/admin',
		use: 'edit',
		hiddenSideNav: true,
	},
	{
		id: 'courtlist',
		root: '/court-list',
		name: 'Court List',
		icon: 'ni ni-diamond text-primary',
		component: CourtList,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: false,
	},
	{
		id: 'knownprofile',
		root: '/known-persons',
		params: [':known'],
		name: 'Known Person Profile',
		icon: 'ni ni-lock-circle-open text-primary',
		component: KnownProfile,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'knownpersons',
		root: '/known-persons',
		name: 'Known Persons',
		icon: 'ni ni-lock-circle-open text-primary',
		component: KnownsList,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: false,
	},
	{
		id: 'courtprofileupdate',
		root: '/courtprofile-update',
		params: [':type', ':id', ':idmap'],
		name: 'Court Profile Update',
		icon: 'ni ni-diamond text-primary',
		component: CourtProfileUpdateForm,
		layout: '/admin',
		use: 'edit',
		hiddenSideNav: true,
	},
	{
		id: 'courtprofile',
		root: '/courtprofile',
		params: [':id'],
		name: 'Court Profile',
		icon: 'ni ni-diamond text-primary',
		component: CourtProfile,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'ypmatchtarget',
		root: '/young-person-verification/likely-matches',
		params: [':id'],
		name: 'Young Person Verification Likely Matches Targetted Search',
		icon: 'ni ni-check-bold text-primary',
		component: YPVerification,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'ypmatch',
		root: '/young-person-verification/likely-matches',
		name: 'Young Person Verification Likely Matches',
		icon: 'ni ni-check-bold text-primary',
		component: YPVerification,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'unknownstarget',
		root: '/young-person-verification/unknowns',
		params: [':id'],
		name: 'Young Person Verification No Matches',
		icon: 'ni ni-check-bold text-primary',
		component: YPNoMatch,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'unknowns',
		root: '/young-person-verification/unknowns',
		name: 'Young Person Verification No Matches',
		icon: 'ni ni-check-bold text-primary',
		component: YPNoMatch,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'yplist',
		root: '/young-person-verification',
		name: 'Young Person Verification',
		icon: 'ni ni-check-bold text-primary',
		component: YPVerificationList,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: false,
	},
	{
		id: 'verifyyp',
		root: '/verify-young-person',
		params: [':id'],
		name: 'Verify Young Person',
		icon: 'ni ni-delivery-fast text-primary',
		component: VerifyYP,
		layout: '/admin',
		use: 'edit',
		hiddenSideNav: true,
	},
	{
		id: 'reportinglist',
		root: '/reporting-list',
		name: 'Reporting',
		icon: 'ni ni-sound-wave text-primary',
		component: ReportingList,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: false,
	},
	{
		id: 'reporting',
		root: '/reporting',
		params: [':id'],
		name: 'Reporting',
		icon: 'ni ni-sound-wave text-primary',
		component: Reporting,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'adduser',
		root: '/add-user',
		name: 'Add User',
		icon: 'ni ni-lock-circle-open text-primary',
		component: AddUser,
		layout: '/admin',
		use: 'edit',
		hiddenSideNav: true,
	},
	{
		id: 'profile',
		root: '/profile',
		params: [':id', ':known'],
		name: 'Profile',
		icon: 'ni ni-circle-08 text-primary',
		component: Profile,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'profileupdate',
		root: '/profile-update',
		params: [':client',':referral'],
		name: 'Update Profile',
		icon: 'ni ni-single-02 text-primary',
		component: ProfileUpdate,
		layout: '/admin',
		use: 'edit',
		hiddenSideNav: true,
	},
	{
		id: 'profilenotes',
		root: '/profile-notes',
		params: [':client',':referral'],
		name: 'Profile Notes',
		icon: 'ni ni-single-02 text-primary',
		component: Notes,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'profilehistory',
		root: '/profile-history',
		params: [':client',':referral', ':type'],
		name: 'View History',
		icon: 'ni ni-single-02 text-primary',
		component: History,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'profileuploads',
		root: '/profile-uploads',
		params: [':client',':referral'],
		name: 'Profile Uploads',
		icon: 'ni ni-single-02 text-primary',
		component: Uploads,
		layout: '/admin',
		use: 'view',
		hiddenSideNav: true,
	},
	{
		id: 'closecase',
		root: '/profile-close-case',
		params: [':client',':referral'],
		name: 'Close Case',
		icon: 'ni ni-single-02 text-primary',
		component: CloseCase,
		layout: '/admin',
		use: 'edit',
		hiddenSideNav: true,
	},
];

// create path name dynamically
routes.map(route => (
	route.hasOwnProperty('root') ? (
		route.hasOwnProperty('params') ? (
			route.path = [route.root, route.params.join('/')].join('/')
		) : route.path = route.root
	): null
));

export default routes;
