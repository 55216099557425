import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

// plugins styles from node_modules
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import "react-datetime/css/react-datetime.css";
// plugins styles downloaded
//import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
//import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import 'assets/scss/det.scss?v1.1.0';
import 'assets/css/amplify.css';

import AdminLayout from 'layouts/Admin';

ReactDOM.render(
	<BrowserRouter>
		<Switch>
			<Route path="/admin" exact={true} render={props => <AdminLayout {...props} />} />
			<Route path="/" render={props => <AdminLayout {...props} />} />
			<Redirect from="*" to="/" />
		</Switch>
	</BrowserRouter>,
	document.getElementById('root'),
);
