import React from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal } from 'reactstrap';
import { formatDate } from 'utils/dateFormatter'

import ProfileHeader from 'components/Headers/ProfileHeader';
import Loading from 'components/General/Loading';
import TimelineCard from 'components/General/TimelineCard';
import ProfileCard from 'views/pages/profile/ProfileCard';
import KnownProfileTopCard from 'views/pages/court/KnownProfileTopCard';
import secureAPI from 'utils/secureAPI';

const KnownProfile = props => {
	const [info, setInfo] = useState({})
	const [court, setCourt] = useState([]);
	const [advice, setAdvice] = useState([]);
	const [cases, setCases] = useState([]);
	const [school, setSchool] = useState({});
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(true);
	const params = useParams();

	useEffect(() => {
		let canSet = true;
		secureAPI(
			{
				endpoint: 'KNOWNS',
				method: 'POST'
			},
			{
				CLIENTID_COURT: params.known,
				responseType: 'json',
			}
		)
			.then(response => {
				if (canSet) {
					setInfo(response.data.info);
					setCourt(response.data.court);
					setAdvice(response.data.advice);
					setCases(response.data.cases);
					setSchool(response.data.school);
					setName(response.data.info['FirstName'] + ' ' + response.data.info['LastName']);
					setLoading(false);
				}
			})
			.catch(error => {
				console.log(error);
			});
		
		return () => canSet = false;
	}, [params.known]);
	
	const attendance = (val) => (val === null || val === undefined) ? null : `${val}`

	const schoolInfo = {
		title: 'Last Enrolment Details',
		link: '',
		colSize: 14,
		rowGroup: {
			colSize: 12,
			textWidth: 6,
			dataWidth: 6,
			rows: [
				{ name: 'Date Last Updated', data: formatDate(school['LastUpdate']) },
				{ name: 'Current Enrolment', data: school['Enrolment1'] },
				{ name: 'Current Entry Date', data: formatDate(school['EnrolmentDate1']) },
				{ name: 'Current Exit Date', data: formatDate(school['ExitDate1']) },
				{ name: 'Previous Enrolment', data: school['Enrolment2'] },
				{ name: 'Previous Entry Date', data: formatDate(school['EnrolmentDate2']) },
				{ name: 'Previous Exit Date', data: formatDate(school['ExitDate2']) },
				{ name: 'Previous Term 1 Attendance', data: attendance(school['Enrolment2AttendanceTerm1']) },
				{ name: 'Previous Term 2 Attendance', data: attendance(school['Enrolment2AttendanceTerm2']) },
				{ name: 'Previous Term 3 Attendance', data: attendance(school['Enrolment2AttendanceTerm3']) },
				{ name: 'Previous Term 4 Attendance', data: attendance(school['Enrolment2AttendanceTerm4']) },
				{ name: 'Total Enrolment Number', data: school['TotalEnrolments'] },
			].filter(row => row.data),
		},
	};

	const attendanceInfo = {
		title: 'Current Enrolment Attendance Details',
		link: '',
		colSize: 14,
		rowGroup: {
			colSize: 12,
			textWidth: 6,
			dataWidth: 6,
			rows: [
				{ name: 'Attendance Data Source', data: school['AttendanceDataSource'] },

				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm1Year']} Attendance `, data:  ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm1']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm1']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm1']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm1']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm1']) },

				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm2Year']} Attendance`, data: ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm2']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm2']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm2']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm2']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm2']) },

				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm3Year']} Attendance`, data: ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm3']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm3']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm3']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm3']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm3']) },
				
				{ name: `\n`, data:  `\n` },
				{ name: `\n`, data:  `\n` },
				{ name: `${school['Enrolment1AttendanceTerm4Year']} Attendance`, data: ` ` },
				{ name: `\n`, data:  `\n` },
				{ name: `Approved Absence Days`, data: attendance(school['Enrolment1ApprovedAbsenceDaysTerm4']) },
				{ name: `Unapproved Absence Days`, data: attendance(school['Enrolment1UnapprovedAbsenceDaysTerm4']) },
				{ name: `Suspension Days`, data: attendance(school['Enrolment1SuspensionDaysTerm4']) },
				{ name: `Total Absence Days`, data: attendance(school['Enrolment1TotalAbsencesDaysTerm4']) },
				{ name: 'Date Last Updated', data: formatDate(school['Enrolment1UploadDateTerm4']) },
			].filter(row => row.data),
		},
	};
	
	const setBlank = val => val ? val : '-';

	const summaryInfo = {
		title: 'Known Person Info',
		link: '',
		colSize: 14,
		rowGroup: {
			colSize: 12,
			textWidth: 6,
			dataWidth: 6,
			rows: [
				{ name: 'Young Person ID', data: setBlank(info['CLIENTID_COURT']) },
				{ name: 'EJI Referral ID', data: setBlank(info['ReferralID']) },
				{ name: 'Court Case Type', data: setBlank(info['CourtHearingType']) },
				{ name: 'Court Case Number', data: setBlank(info['CourtCaseN']) },
			].filter(row => row.data),
		},
	}
	
	const StandardProfile = props => (
		<Row className="card-wrapper">
			<Col lg="7">
				<TimelineCard
					header={{
						title: "Brief Advice"
					}}
					body={{
						data: advice,
						date: "AdviceDT",
						target: "Advice",
						nodata: `No advice on file for ${name}`,
						icon: "ni ni-bell-55",
						iconStyle: "timeline-step badge-success"
					}}
				/>
				<TimelineCard
					header={{
						title: "Case History"
					}}
					body={{
						data: cases,
						date: "CaseDT",
						target: "CaseInfo",
						nodata: `No cases on file for ${name}`,
						icon: "ni ni-bell-55",
						iconStyle: "timeline-step badge-secondary"
					}}
				/>
				<TimelineCard
					header={{
						title: "Court Appearance History"
					}}
					body={{
						data: court,
						date: "CourtDT",
						dateType: "short",
						target: "Court",
						nodata: `No court appearances on file for ${name}`,
						icon: "ni ni-bell-55",
						iconStyle: "timeline-step badge-warning"
					}}
				/>
			</Col>
			<Col lg="5">
				{props.summary ? (
					<ProfileCard data={summaryInfo}></ProfileCard>
				) : null}
				<ProfileCard data={schoolInfo}></ProfileCard>
				<ProfileCard data={attendanceInfo}></ProfileCard>
			</Col>
		</Row>
	)

	return !loading ? (
		<>
			{props.component ? null : (
				<ProfileHeader
					title="Known Persons Profile"
					name={name}
					parentName="Known Persons List"
					parentLink={`${props.baseRoute}/known-persons`}
					baseRoute={props.baseRoute}
				/>
			)}
			{props.component ? (
				<StandardProfile summary={true}/>
			) : (
				<Container className="mt--6" fluid>
					<KnownProfileTopCard data={info} user={props.user} baseRoute={props.baseRoute} />
					<StandardProfile />	
				</Container>
			)}
		</>
	) : (
		<>
			{props.component ? (
				<Container>
					<Row>
						<Col className="text-center">
							<Loading />
						</Col>
					</Row>
				</Container>
			) : (
				<Modal className="modal-dialog-centered" isOpen={loading}>
					<div className="modal-header">
						<h6 className="modal-title" id="modal-title-default">
							Loading Known Person's Profile ....
						</h6>
					</div>
					<div className="modal-body">
						<Container>
							<Row>
								<Col className="text-center">
									<Loading />
								</Col>
							</Row>
						</Container>
					</div>
				</Modal>
			)}	
		</>
	);
};

export default KnownProfile;
