import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import Flags from 'assets/img/brand/flags.png';

const AdminFooter = () => {
	return (
		<>
			<Container fluid>
				<footer className="footer pt-0">
					State Government of Victoria, Australia © {new Date().getFullYear()}
					<Row>
						<Col className="col-sm">
							We respectfully acknowledge the Traditional Owners of the country throughout Victoria and pay
							respect to the ongoing living cultures of First Peoples.
						</Col>
						<Col className="col-auto">
							<img src={Flags} alt="traditional owners flags" style={{ height: '30px' }} />
						</Col>
					</Row>
				</footer>
			</Container>
		</>
	);
};

export default AdminFooter;
