import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, Container, Row, Col } from 'reactstrap';

import PageHeader from 'components/Headers/PageHeader';

const YPVerificationList = props => {
	const history = useHistory();

	const hubYPClick = matchType => {
		history.push(`${props.baseRoute}/young-person-verification/${matchType}`);
	};

	return (
		<>
			<PageHeader name="Young Person Verification" parentName="Hub" />

			<Container className="mt--6" fluid>
				<Row className="card-wrapper">
                    <Col lg="4" key="likely-matches">
                        <Card
                            className="card-pricing bg-gradient-yellow"
                            tag="a"
                            onClick={() => hubYPClick('likely-matches')}
                        >
                            <CardBody>
                                <Row>
                                    <Col className="col">
                                        <CardTitle tag="h5" className="h2 font-weight-bold">
                                            Likely Matches
                                        </CardTitle>
                                        <CardText>
                                            Table of young persons with likely matches in the EJI database.
                                        </CardText>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={e => e.preventDefault()}
                                        >
                                            View matches
                                        </button>
                                    </Col>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
                                            <i className="ni ni-badge"/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="4" key="no-matches">
                        <Card
                            className="card-pricing bg-gradient-yellow"
                            tag="a"
                            onClick={() => hubYPClick('unknowns')}
                        >
                            <CardBody>
                                <Row>
                                    <Col className="col">
                                        <CardTitle tag="h5" className="h2 font-weight-bold">
                                            Unknown
                                        </CardTitle>
                                        <CardText>
                                            Table of young persons with no likely matches found the EJI database.
                                        </CardText>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={e => e.preventDefault()}
                                        >
                                            View unknowns
                                        </button>
                                    </Col>
                                    <Col className="col-auto">
                                        <div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
                                            <i className="ni ni-support-16"/>
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
				</Row>
			</Container>
		</>
	);
};

export default YPVerificationList;
