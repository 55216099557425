import { useEffect } from 'react';
import { Auth } from '@aws-amplify/auth';

const useAuthState = () => {

    const signOut = async () => { await Auth.signOut() }

    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true
        })
            .then(() => {})
            .catch(err => signOut());
    });
    return null
};

export default useAuthState;