import React from 'react';
import { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import TimelineCard from 'components/General/TimelineCard';
import secureAPI from 'utils/secureAPI';

const Uploads = props => {
	const [uploads, setUploads] = useState({});
	const [name, setName] = useState('');
	const [responded, setResponded] = useState({});
	const [loading, setLoading] = useState(true);
	const [collapsedState, setCollapse] = useState([]);
	let params = useParams();

	useEffect(() => {
		let canSet = true;
		secureAPI(
			{
				endpoint: 'PROFILE',
				method: 'POST',
			},
			{
				ReferralID: params.referral,
				responseType: 'json',
			},
		)
			.then(response => {
				if (canSet) {
					setName(response.data.profile['First name'] + ' ' + response.data.profile['Last name']);
					setResponded(r => ({ ...r, name: true }));
				}
			})
			.catch(error => {
				console.log(error);
			});

		secureAPI(
			{
				endpoint: 'NOTES',
				method: 'POST',
			},
			{
				ReferralID: params.referral,
				Uploads: 'true',
				responseType: 'json',
			},
		)
			.then(response => {
				if (canSet) {
					setUploads(response.data);
					setResponded(r => ({ ...r, uploads: true }));
				}
			})
			.catch(error => {
				console.log(error);
			});
		return () => (canSet = false);
	}, [params.referral]);

	useEffect(() => {
		let canSet = true;
		if (responded.name === true && responded.uploads === true && canSet) {
			setLoading(false);
		}
		return () => (canSet = false);
	}, [responded]);

	return !loading ? (
		<>
			{console.log('Render')}
			<ProfileUpdateHeader
				name="Uploads"
				refId={params.referral}
				clientId={params.client}
				parentName={name}
				baseRoute={props.baseRoute}
			/>
			<Container className="mt--6" fluid>
				<Row>
					<Col lg="5">
						<TimelineCard
							header={{
								title: 'Upload History',
							}}
							body={{
								data: uploads.history,
								date: 'EndDate',
								target: 'Upload contents',
								nodata: `No uploads on file for ${name}`,
								icon: 'ni ni-bell-55',
								iconStyle: 'timeline-step badge-success',
							}}
						/>
					</Col>
					<Col lg="7">
						<div className="accordion">
							<TimelineCard
								header={{
									title: 'Individual Education Plans',
									type: 'collapse',
									collapseIndex: '1',
									state: collapsedState,
									stateFunc: setCollapse,
								}}
								body={{
									data: uploads.plans,
									date: 'EndDate',
									target: 'Individual education plan',
									nodata: `No education plans on file for ${name}`,
									icon: 'ni ni-bell-55',
									iconStyle: 'timeline-step badge-primary',
								}}
							/>
						</div>
						<div className="accordion">
							<TimelineCard
								header={{
									title: 'Relevant Emails',
									type: 'collapse',
									collapseIndex: '2',
									state: collapsedState,
									stateFunc: setCollapse,
								}}
								body={{
									data: uploads.emails,
									date: 'EndDate',
									target: 'Relevant emails',
									nodata: `No relevant emails on file for ${name}`,
									icon: 'ni ni-bell-55',
									iconStyle: 'timeline-step badge-secondary',
								}}
							/>
						</div>
						<div className="accordion">
							<TimelineCard
								header={{
									title: 'Other plans',
									type: 'collapse',
									collapseIndex: '3',
									state: collapsedState,
									stateFunc: setCollapse,
								}}
								body={{
									data: uploads.other,
									date: 'EndDate',
									target: 'Other plans',
									nodata: `No other plans on file for ${name}`,
									icon: 'ni ni-bell-55',
									iconStyle: 'timeline-step badge-warning',
								}}
							/>
						</div>
						<div className="accordion">
							<TimelineCard
								header={{
									title: 'Education Summary',
									type: 'collapse',
									collapseIndex: '4',
									state: collapsedState,
									stateFunc: setCollapse,
								}}
								body={{
									data: uploads.education,
									date: 'EndDate',
									target: 'Education Summary Report',
									nodata: `No education summary on file for ${name}`,
									icon: 'ni ni-bell-55',
									iconStyle: 'timeline-step badge-warning',
								}}
							/>
						</div>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Uploads ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default Uploads;
