import React from 'react';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardHeader, CardBody, Row, Col, CardFooter, Nav, NavItem, NavLink } from 'reactstrap';
import {
	handleUpdate,
	handleNotes,
	handleStatusHistory,
	handleUpdateHistory,
	handleCloseCase,
	handleUploads,
} from 'utils/handles';

import ProfileRowGroup from 'views/pages/profile/ProfileRowGroup.js';
import { getCurrentAgeFromDOBString } from 'utils/mathFunctions';
import { setBlank } from 'utils/stringFormatter';

const ProfileTopCard = props => {
	const rg1 = {
		colSize: 4,
		textWidth: 5,
		dataWidth: 7,
		rows: [
			{ name: 'Preferred name', data: setBlank(props.data['Preferred name']) },
			{ name: 'Date of birth', data: setBlank(props.data['Date of birth']) },
			{ name: 'Age', data: setBlank(getCurrentAgeFromDOBString(props.data['Date of birth'])) },
			{ name: 'Gender', data: setBlank(props.data['Gender identity']) },
			{ name: 'ATSI Status', data: setBlank(props.data['ATSI']) },
			{ name: 'Case type', data: setBlank(props.data['EJI case type']) },
		],
	};

	const rg2 = {
		colSize: 6,
		textWidth: 5,
		dataWidth: 7,
		rows: [
			{ name: 'DET area', data: setBlank(props.data['DET Area']) },
			{ name: 'DET region', data: setBlank(props.data['SchoolRegion']) },
			{ name: 'Preferred Education Setting', data: setBlank(props.data['Education setting']) },
			{ name: 'Status', data: setBlank(props.data['Case status']) },
			{ name: 'EJI Team Member', data: setBlank(props.data['Case worker']) },
			{ name: 'EEIO Flag', data: setBlank(props.data['EarlyEducationInterventionFlag']) },
		],
	};

	let history = useHistory();

	return (
		<>
			<Row className="card-wrapper">
				<Col lg="12">
					<Card>
						<CardHeader>
							<Row className="align-items-center">
								<Col xs="8">
									<h5 className="h2 mb-0">
										{props.data['First name']} {props.data['Last name']}
									</h5>
								</Col>
								{props.user.attributes['custom:eji_role'] === 'admin' ? (
									<Col className="text-right" xs="4">
										<Button
											color="default"
											href="#"
											onClick={e =>
												handleUpdate(
													e,
													history,
													props.data['ClientID'],
													props.data['ReferralID'],
													props.baseRoute,
												)
											}
											size="l"
										>
											<i className="fas fa-edit" style={{ margin: '0 10px 0 0' }}></i>Update
											Profile
										</Button>
									</Col>
								) : null}
							</Row>
						</CardHeader>

						<CardBody>
							<Row>
								<ProfileRowGroup data={rg1} />
								<ProfileRowGroup data={rg2} />

								<Col lg="1">
									<Row>
										<Button
											block
											color="primary"
											size="sm"
											type="button"
											className="mb-1"
											onClick={e =>
												handleNotes(
													e,
													history,
													props.data['ClientID'],
													props.data['ReferralID'],
													props.baseRoute,
												)
											}
										>
											Notes
										</Button>
									</Row>
									<Row>
										<Button
											block
											color="primary"
											size="sm"
											type="button"
											className="mb-1"
											onClick={e =>
												handleStatusHistory(
													e,
													history,
													props.data['ClientID'],
													props.data['ReferralID'],
													props.baseRoute,
												)
											}
										>
											Client Timeline
										</Button>
									</Row>
									<Row>
										<Button
											block
											color="primary"
											size="sm"
											type="button"
											className="mb-1"
											onClick={e =>
												handleUpdateHistory(
													e,
													history,
													props.data['ClientID'],
													props.data['ReferralID'],
													props.baseRoute,
												)
											}
										>
											Update History
										</Button>
									</Row>
									{props.user.attributes['custom:eji_role'] === 'admin' && 
										props.data['Case status'] !== 'Closed' ? (
										<Row>
											<Button
												block
												color="primary"
												size="sm"
												type="button"
												className="mb-1"
												onClick={e =>
													handleCloseCase(
														e,
														history,
														props.data['ClientID'],
														props.data['ReferralID'],
														props.baseRoute,
													)
												}
											>
												Close Case
											</Button>
										</Row>
									) : null}
									<Row>
										<Button
											block
											color="primary"
											size="sm"
											type="button"
											className="mb-1"
											onClick={e =>
												handleUploads(
													e,
													history,
													props.data['ClientID'],
													props.data['ReferralID'],
													props.baseRoute,
												)
											}
										>
											Uploads
										</Button>
									</Row>
								</Col>
							</Row>
						</CardBody>
						<CardFooter>
							<div className="nav-wrapper">
								<Nav
									className="nav-fill flex-column flex-md-row"
									id="tabs-icons-text"
									pills
									role="tablist"
								>
									<NavItem>
										<NavLink
											aria-selected={props.tabs === 'profile'}
											className={classnames('mb-sm-3 mb-md-0', {
												active: props.tabs === 'profile',
											})}
											onClick={() => props.tabFunc('profile')}
											role="tab"
										>
											<i className="ni ni-collection mr-2" />
											Profile
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											aria-selected={props.tabs === 'known'}
											className={classnames('mb-sm-3 mb-md-0', {
												active: props.tabs === 'known',
											})}
											onClick={() => props.tabFunc('known')}
											role="tab"
										>
											<i className="ni ni-folder-17 mr-2" />
											Known Person Data
										</NavLink>
									</NavItem>
								</Nav>
							</div>
						</CardFooter>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default ProfileTopCard;
