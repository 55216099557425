import React from 'react';
import { DropdownItem } from 'reactstrap';
import routes from 'routes';

// handle all redirection
export const handleRedirect = (e, pathObject, argsArray) => {
	e.preventDefault();
	const route = routes.find(el => el.id === pathObject.pathid);
	pathObject.history.push({
		pathname: `${pathObject.baseRoute}${route.root}/${argsArray.join('/')}`,
		state: {
			parentName: pathObject.parentName,
			parentUrl: pathObject.parentUrl,
		},
	});
};

export const handleCase = (e, history, clientID, refID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/profile/${refID}/${clientID}`);
};

export const handleCloseCase = (e, history, clientID, refID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/profile-close-case/${clientID}/${refID}`);
};

export const handleCreate = (e, history, clientID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/new-case/${clientID}`);
};

export const handleNotes = (e, history, clientID, refID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/profile-notes/${clientID}/${refID}`);
};

export const handleUploads = (e, history, clientID, refID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/profile-uploads/${clientID}/${refID}`);
};

export const handleOptions = (verifyMatch, hist, clientID, refID, caseStatus, baseRoute, fullName) => {
	const dropdownOptions = {
		1: (
			<DropdownItem onClick={e => handleVerify(e, hist, fullName, baseRoute)}>
				<i className="fas fa-check" style={{ margin: '0 10px 0 0' }}></i>
				View Verification
			</DropdownItem>
		),
		2: (
			<DropdownItem onClick={e => handleCase(e, hist, clientID, refID, baseRoute)}>
				<i className="fas fa-folder" style={{ margin: '0 10px 0 0' }}></i>
				View Case
			</DropdownItem>
		),
		3:
			baseRoute === '/admin' ? (
				<>
					<DropdownItem onClick={e => handleCreate(e, hist, clientID, baseRoute)}>
						<i className="fas fa-briefcase-medical" style={{ margin: '0 10px 0 0' }}></i>
						Create Case
					</DropdownItem>
				</>
			) : null,
	};
	const allOptions =
		caseStatus === 'Closed' || caseStatus === null
			? {
					Verify: dropdownOptions[1],
					Matched: dropdownOptions[3],
					'No Match': dropdownOptions[3],
			  }
			: {
					Verify: dropdownOptions[1],
					Matched: dropdownOptions[2],
					'No Match': dropdownOptions[2],
			  };

	return verifyMatch ? allOptions[verifyMatch] : allOptions['No Match'];
};

export const handleStatusHistory = (e, history, clientID, refID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/profile-history/${clientID}/${refID}/status`);
};

export const handleUpdateHistory = (e, history, clientID, refID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/profile-history/${clientID}/${refID}/updates`);
};

export const handleUpdate = (e, history, clientID, refID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/profile-update/${clientID}/${refID}`);
};

export const handleVerify = (e, history, clientID, baseRoute) => {
	e.preventDefault();
	history.push(`${baseRoute}/young-person-verification/likely-matches/${clientID}`);
};
