import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, CardText, CardTitle, CardFooter, Container, Row, Col } from 'reactstrap';

import PageHeader from 'components/Headers/PageHeader';
import { Reports } from 'config/reports';

const ReportingList = props => {
	const history = useHistory();

	const hubReportClick = id => {
		history.push(`${props.baseRoute}/reporting/${id}`);
	};

	const maxRowSize = 3;

	const createCards = array => array.map((r, i) => (
		<Card
			className="card-pricing bg-gradient-yellow"
			tag="a"
			key={i}
			onClick={() => hubReportClick(r.id)}
		>
			<CardBody>
				<Row>
					<Col className="col">
						<CardTitle tag="h5" className="h2 font-weight-bold">
							{r.title}
						</CardTitle>
						<CardText>{r.description}</CardText>
					</Col>
					<Col className="col-auto">
						<div className="icon icon-shape bg-gradient-yellow text-white rounded-circle shadow">
							<i className="ni ni-chart-bar-32" />
						</div>
					</Col>
				</Row>
			</CardBody>
			<CardFooter className="border-0">
				<button
					className="btn btn-primary"
					onClick={e => e.preventDefault()}
				>
					Open {r.title}
				</button>
			</CardFooter>
		</Card>
	))

	const createRows = (array, row_size) => {
		let matrix = []
		for (var i = 0; i < array.length; i += row_size) {
			let temp_array = array.slice(i, i + row_size)
			matrix.push(temp_array)
		}
		
		return matrix.map((row, index) => {
			if (row.length === row_size) {
				return (
					<Row key={index}>
						<Col>
							<div className="card-deck">
								{createCards(row)}
							</div>
						</Col>
					</Row>
				)
			} else {
				return (
					<Row key={index}>
						<Col lg={(12 / row_size) * row.length}>
							<div className="card-deck">
								{createCards(row)}
							</div>
						</Col>
					</Row>
				)
			}
		})
	};
	return (
		<>
			<PageHeader name="Reporting" parentName="Hub" />

			<Container className="mt--6" fluid>
				{createRows(Reports, maxRowSize)}
			</Container>
		</>
	);
};

export default ReportingList;
