import React from 'react';
import { Spinner } from 'reactstrap';

const Loading = () => {
	return (
		<>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<Spinner type="grow" className="text-primary"></Spinner>
			<span className=" sr-only">Loading...</span>
		</>
	);
};

export default Loading;
