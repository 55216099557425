import React from 'react';
import { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import { formatDateTime } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';

import { Chrono } from 'react-chrono';

const History = props => {
	const [history, setHistory] = useState([]);
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(true);
	let params = useParams();

	const pageName = params.type === 'status' ? 'Client Timeline' : 'Update History';

	useEffect(() => {
		let canSet = true;
		secureAPI(
			{
				endpoint: 'PROFILE',
				method: 'POST',
			},
			{
				ReferralID: params.referral,
				responseType: 'json',
			},
		)
			.then(response => {
				if (canSet) {
					setName(response.data.profile['First name'] + ' ' + response.data.profile['Last name']);
				}
			})
			.catch(error => {
				console.log(error);
			});
		secureAPI(
			{
				endpoint: 'HISTORY',
				method: 'POST',
			},
			{
				ClientID: params.client,
				type: params.type,
				responseType: 'json',
			},
		)
			.then(response => {
				// TODO: create chrono here
				response.data.map(data => (data.title = formatDateTime(data.title)));
				if (canSet) {
					setHistory(response.data);
					setLoading(false);
				}
			})
			.catch(error => {
				console.log(error);
			});
		return () => (canSet = false);
	}, [params.referral, params.client, params.type]);

	return !loading ? (
		<>
			<ProfileUpdateHeader
				name={pageName}
				refId={params.referral}
				clientId={params.client}
				parentName={name}
				baseRoute={props.baseRoute}
			/>
			<Container className="mt--6" fluid>
				<Row>
					<Col lg="12">
						<Card>
							<CardHeader className="bg-transparent">
								<h3 className="mb-0">{pageName}</h3>
							</CardHeader>
							<CardBody>
								{history.every(
									item => item.hasOwnProperty('title') && item.hasOwnProperty('cardTitle'),
								) && history.length > 0 ? (
									<Chrono
										items={history}
										mode="VERTICAL_ALTERNATING"
										theme={{
											primary: '#b5374f',
											secondary: 'white',
										}}
										cardHeight={150}
										hideControls={true}
										scrollable
									/>
								) : (
									<p>No history on file for {name}</p>
								)}
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading History ....
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default History;
