import React from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { useState, useEffect } from 'react';
import { Card, Container, Row, Col, Modal } from 'reactstrap';
import { useParams } from 'react-router-dom';

import ProfileUpdateHeader from 'components/Headers/ProfileUpdateHeader';
import Loading from 'components/General/Loading';
import { Endpoints } from 'config/endpoints';
import { iframeOptions, iframeStyles } from 'config/iframes';
import secureAPI from 'utils/secureAPI';

const ProfileUpdate = props => {
	const [name, setName] = useState('');
	const [loading, setLoading] = useState(true);

	const params = useParams();

	const userEmail = props.user.attributes
		? props.user.attributes.email
		: props.user.username.replace('onelogin_', '');
	const updateUrl =
		Endpoints.UPDATE_REFERRAL +
		'?ClientID=' +
		params.client +
		'&ReferralID=' +
		params.referral +
		'&userEmail=' +
		userEmail;

	useEffect(() => {
		secureAPI(
			{
				endpoint: 'PROFILE',
				method: 'POST',
			},
			{
				ReferralID: params.referral,
				responseType: 'json',
			},
		)
			.then(response => {
				setName(response.data.profile['First name'] + ' ' + response.data.profile['Last name']);
				setLoading(false);
			})
			.catch(error => {
				console.log(error);
			});
	}, [params.referral, params.client]);

	return !loading ? (
		<>
			<ProfileUpdateHeader
				name="Update Case"
				refId={params.referral}
				clientId={params.client}
				parentName={name}
				baseRoute={props.baseRoute}
			/>
			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card
							style={{
								height: '76vh',
								background: '#F3F3F3',
							}}
						>
							<ReactIframeResizer
								iframeResizerOptions={iframeOptions}
								iframeResizerUrl={false}
								src={updateUrl}
								style={iframeStyles}
							></ReactIframeResizer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	) : (
		<>
			<Modal className="modal-dialog-centered" isOpen={loading}>
				<div className="modal-header">
					<h6 className="modal-title" id="modal-title-default">
						Loading Profile Update...
					</h6>
				</div>
				<div className="modal-body">
					<Container>
						<Row>
							<Col className="text-center">
								<Loading />
							</Col>
						</Row>
					</Container>
				</div>
			</Modal>
		</>
	);
};

export default ProfileUpdate;
