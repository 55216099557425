const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const QUALTRICS_BASE_URL = process.env.REACT_APP_QUALTRICS_BASE_URL;
const CLOSE_CASE_FORM = process.env.REACT_APP_CLOSE_CASE_FORM;
const NEW_CASE_YP_FORM = process.env.REACT_APP_NEW_CASE_YP_FORM;
const NEW_CASE_STAFF_FORM = process.env.REACT_APP_NEW_CASE_STAFF_FORM;
const UPDATE_REFERRAL_FORM = process.env.REACT_APP_UPDATE_REFERRAL_FORM;
const UPDATE_COURT_FORM = process.env.REACT_APP_UPDATE_COURT_FORM;
const VERIFY_YP_FORM = process.env.REACT_APP_VERIFY_YP_FORM;

export const Endpoints = {
	CASES: `${BASE_URL}/reflist/app`,
	PROFILE: `${BASE_URL}/refret/app`,
	COURT: `${BASE_URL}/court/app`,
	LINK: `${BASE_URL}/link-case`,
	COURT_PROFILE: `${BASE_URL}/courtprofile/app`,
	KNOWNS: `${BASE_URL}/knownlist/app`,
	NOTES: `${BASE_URL}/notes/app`,
	HISTORY: `${BASE_URL}/refup/app`,
	YP_VERIFICATION: `${BASE_URL}/vt/app`,
	EEIREFERRAL: `${BASE_URL}/knownpersonseeireferral/app`,
	CLOSE_CASE: QUALTRICS_BASE_URL + CLOSE_CASE_FORM,
	NEW_CASE_YP: QUALTRICS_BASE_URL + NEW_CASE_YP_FORM,
	NEW_CASE_STAFF: QUALTRICS_BASE_URL + NEW_CASE_STAFF_FORM,
	UPDATE_REFERRAL: QUALTRICS_BASE_URL + UPDATE_REFERRAL_FORM,
	UPDATE_COURT: QUALTRICS_BASE_URL + UPDATE_COURT_FORM,
	VERIFY_YP: QUALTRICS_BASE_URL + VERIFY_YP_FORM,
	ADD_USER: '',
	REPORTING: '< refer to config/reports.js >',
	APP:
		'http://localhost:3000/, https://app.ejiprogram.com/, https://app.dev-ejiprogram.com/, https://master.d3tkw8df679hcl.amplifyapp.com/, https://prod.d3tkw8df679hcl.amplifyapp.com/, https://test-env.d3tkw8df679hcl.amplifyapp.com/, https://dev.dpp2a8if5astb.amplifyapp.com/',
};
