export const getCurrentAgeFromDOBString = dobString => {
	// return null if the dobString is not a string and if falsey
	if (!dobString || typeof dobString !== 'string') {
		return null;
	}
	// create an int array of [YYYY, MM, DD]
	const dobArray = dobString
		.split('/')
		.map(el => parseInt(el))
		.reverse();

	// get the date difference from now and the time in ms at the birthdate
	const difference = Date.now() - new Date(dobArray).getTime();

	// get the date of the difference
	// this relies on getting the date from time passing in ms since epoch
	const ageDate = new Date(difference);

	// get year for age
	// note that UTC epoch is in 1/1/1970
	return Math.abs(ageDate.getUTCFullYear() - 1970);
};
