import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { Button, Card, Container, Row, Col, UncontrolledTooltip } from 'reactstrap';
import { Link } from 'react-router-dom';

import Loading from 'components/General/Loading';
import { formatDateTime, formatLongDate } from 'utils/dateFormatter';
import secureAPI from 'utils/secureAPI';

const pagination = paginationFactory({
	page: 1,
	alwaysShowAllBtns: true,
	showTotal: true,
	withFirstAndLast: false,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} results
		</span>
	),
	sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
		<div className="dataTables_length" id="datatable-basic_length">
			<label>
				Show
				{
					<select
						name="datatable-basic_length"
						aria-controls="datatable-basic"
						className="form-control form-control-sm"
						onChange={e => onSizePerPageChange(e.target.value)}
					>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="30">30</option>
						<option value="40">40</option>
						<option value="50">50</option>
					</select>
				}
				entries.
			</label>
		</div>
	),
});

const { SearchBar } = Search;

let courtFilter;
let statusFilter;
let areaFilter;
let regionFilter;

const getSortedDistinct = (array, key) =>
	[...new Set(array.map(el => el[key]))].sort().reduce(function(result, item, index, array) {
		if (item && item !== undefined) {
			result[item] = item;
		}
		return result;
	}, {});

const CaseListTable = props => {
	const [alert, setAlert] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(true);
	const [selectOptions, setOptions] = useState({
		courts: {},
		statuses: {},
		areas: {},
		regions: {},
	});
	const tableRef = useRef();

	const NoDataIndication = () => (
		<Container fluid className="adapt">
			<Row>
				<Col className="text-center m-6">{loading ? <Loading /> : <div>No data available</div>}</Col>
			</Row>
		</Container>
	);

	useEffect(() => {
		let canSet = true;

		secureAPI(
			{
				endpoint: 'CASES',
				method: 'POST',
			},
			{
				ejiRole: props.user.attributes['custom:eji_role'],
				detRegion: props.user.attributes['custom:DETRegion'],
				responseType: 'json',
			},
		)
			.then(response => {
				response.data.map(data => {
					data['DET Area'] = data['DET Area'] ? data['DET Area'] : 'N/A';
					data['Court DET region'] = data['Court DET region'] ? data['Court DET region'] : 'N/A';
					return data;
				});
				if (canSet) {
					response.data.map(el => (el['Full name'] = `${el['First name']} ${el['Last name']}`));
					const distinctCourts = getSortedDistinct(response.data, 'Court name');
					const distinctStatuses = getSortedDistinct(response.data, 'Case status');
					const distinctAreas = getSortedDistinct(response.data, 'DET Area');
					const distinctRegions = getSortedDistinct(response.data, 'Court DET region');
					setOptions({
						courts: distinctCourts,
						statuses: distinctStatuses,
						areas: distinctAreas,
						regions: distinctRegions,
					});
					setDataTable(response.data);
					setLoading(false);
				}
			})
			.catch(error => {
				console.log(error);
			});
		return () => (canSet = false);
	}, [props.user.attributes]);

	const copyToClipboardAsTable = el => {
		var body = document.body,
			range,
			sel;
		if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			sel.removeAllRanges();
			try {
				range.selectNodeContents(el);
				sel.addRange(range);
			} catch (e) {
				range.selectNode(el);
				sel.addRange(range);
			}
			document.execCommand('copy');
		} else if (body.createTextRange) {
			range = body.createTextRange();
			range.moveToElementText(el);
			range.select();
			range.execCommand('Copy');
		}
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '0px' }}
				title="Good job!"
				onConfirm={() => setAlert([])}
				onCancel={() => setAlert([])}
				confirmBtnBsStyle="info"
				btnSize=""
			>
				Copied to clipboard!
			</ReactBSAlert>,
		);
	};

	const defaultSortedBy = [
		{
			dataField: 'ReportDate',
			order: 'desc',
		},
	];

	const SelectFilterLabel = props => (
		<label>
			{props.name}
			<select
				className="filter select-filter form-control case-filter"
				onChange={e => {
					props.filterFunc(e.target.value);
				}}
			>
				<option value="" key={0}>
					Please Select
				</option>
				{Object.values(props.options).map((option, index) => (
					<option value={option} key={index + 1}>
						{option}
					</option>
				))}
			</select>
		</label>
	);

	const linkFormatter = (cell, row) => {
		return <Link to={`${props.baseRoute}/profile/${row['ReferralID']}/${row['ClientID']}`}>{cell}</Link>;
	};

	const linkDateTimeFormatter = (cell, row) => {
		return (
			<Link to={`${props.baseRoute}/profile/${row['ReferralID']}/${row['ClientID']}`}>
				{formatDateTime(new Date(cell))}
			</Link>
		);
	};

	const linkLongDateFormatter = (cell, row) => {
		return (
			<Link to={`${props.baseRoute}/profile/${row['ReferralID']}/${row['ClientID']}`}>
				{cell ? formatLongDate(new Date(cell)) : null}
			</Link>
		);
	};

	return (
		<>
			{alert}

			<Container className="case-list-table mt--6" fluid>
				<Row>
					<div className="col">
						<Card>
							<ToolkitProvider
								data={dataTable}
								keyField="ReferralID"
								search={{
									searchFormatted: false,
								}}
								columns={[
									{
										dataField: 'ClientID',
										text: 'Client ID',
										sort: true,
										formatter: linkFormatter,
									},
									{
										dataField: 'ReferralID',
										text: 'Case ID',
										sort: true,
										formatter: linkFormatter,
									},
									{
										dataField: 'ReportDate',
										text: 'Date of last update',
										sort: true,
										formatter: linkDateTimeFormatter,
									},
									{
										dataField: 'Referral Date',
										text: 'Referral Date',
										sort: true,
										formatter: linkFormatter,
									},
									{
										dataField: 'ClosureDate',
										text: 'Closure Date',
										sort: true,
										formatter: linkLongDateFormatter,
									},
									{
										dataField: 'First name',
										text: 'First Name',
										sort: true,
										formatter: linkFormatter,
									},
									{
										dataField: 'Last name',
										text: 'Surname',
										sort: true,
										formatter: linkFormatter,
									},
									{
										dataField: 'New school/provider',
										text: 'School',
										sort: true,
										formatter: linkFormatter,
									},
									{
										dataField: 'Court name',
										text: 'Court',
										sort: true,
										formatter: linkFormatter,
										filter: selectFilter({
											options: selectOptions.courts,
											getFilter: filter => {
												courtFilter = filter;
											},
										}),
									},
									{
										dataField: 'DET Area',
										text: 'DET Area',
										sort: true,
										formatter: linkFormatter,
										filter: selectFilter({
											options: selectOptions.areas,
											getFilter: filter => {
												areaFilter = filter;
											},
										}),
									},
									{
										dataField: 'Court DET region',
										text: 'DET Region',
										sort: true,
										formatter: linkFormatter,
										filter: selectFilter({
											options: selectOptions.regions,
											getFilter: filter => {
												regionFilter = filter;
											},
										}),
									},
									{
										dataField: 'Case worker',
										text: 'EJI Team Member',
										sort: true,
										formatter: linkFormatter,
									},
									{
										dataField: 'Case status',
										text: 'Status',
										sort: true,
										formatter: linkFormatter,
										filter: selectFilter({
											options: selectOptions.statuses,
											getFilter: filter => {
												statusFilter = filter;
											},
										}),
									},
									{
										dataField: 'Full name',
										text: 'Status',
										hidden: true,
									},
									{
										dataField: 'EarlyEducationInterventionFlag',
										text: 'EEIO',
										sort: true,
										formatter: linkFormatter,
									},
								]}
							>
								{props => (
									<div className="py-4 table-responsive">
										<Container fluid>
											<Row>
												<Col xs={12} sm={6}>
													<Button
														className="buttons-copy buttons-html5"
														color="default"
														size="sm"
														id="copy-tooltip"
														onClick={() =>
															copyToClipboardAsTable(
																document.getElementById('case-list-table'),
															)
														}
													>
														<span>
															<i
																className="fas fa-copy"
																style={{ margin: '0 10px 0 0' }}
															></i>
															Copy
														</span>
													</Button>
													<ReactToPrint
														trigger={() => (
															<Button
																color="default"
																size="sm"
																className="buttons-copy buttons-html5"
																id="print-tooltip"
															>
																<span>
																	<i
																		className="fas fa-print"
																		style={{ margin: '0 10px 0 0' }}
																	></i>
																	Print
																</span>
															</Button>
														)}
														content={() => tableRef.current}
													/>
													<UncontrolledTooltip placement="top" target="print-tooltip">
														This will open a print page with the visible rows of the table.
													</UncontrolledTooltip>
													<UncontrolledTooltip placement="top" target="copy-tooltip">
														This will copy to your clipboard the visible rows of the table.
													</UncontrolledTooltip>
												</Col>
												<Col xs={12} sm={6}>
													<div
														id="datatable-basic_filter"
														className="dataTables_filter pb-1 float-right"
													>
														<label>
															Search:
															<SearchBar
																className="form-control-sm"
																placeholder=""
																{...props.searchProps}
															/>
														</label>
													</div>
												</Col>
											</Row>
											<Row>
												<Col xs={12} md={6}>
													<div
														id="datatable-select_filter"
														className="dataTables_filter pb-1"
													>
														<SelectFilterLabel
															name="Court:"
															options={selectOptions.courts}
															filterFunc={courtFilter}
														/>
													</div>
												</Col>
												<Col xs={12} md={6}>
													<div
														id="datatable-select_filter"
														className="dataTables_filter pb-1 float-right"
													>
														<SelectFilterLabel
															name="Status:"
															options={selectOptions.statuses}
															filterFunc={statusFilter}
														/>
													</div>
												</Col>
												<Col xs={12} md={6}>
													<div
														id="datatable-select_filter"
														className="dataTables_filter pb-1"
													>
														<SelectFilterLabel
															name="Area:"
															options={selectOptions.areas}
															filterFunc={areaFilter}
														/>
													</div>
												</Col>
												<Col xs={12} md={6}>
													<div
														id="datatable-select_filter"
														className="dataTables_filter pb-1 float-right"
													>
														<SelectFilterLabel
															name="Region:"
															options={selectOptions.regions}
															filterFunc={regionFilter}
														/>
													</div>
												</Col>
											</Row>
										</Container>

										<BootstrapTable
											id="case-list-table"
											ref={tableRef}
											bootstrap4={true}
											bordered={false}
											pagination={pagination}
											filter={filterFactory()}
											defaultSorted={defaultSortedBy}
											noDataIndication={() => <NoDataIndication />}
											{...props.baseProps}
										/>
									</div>
								)}
							</ToolkitProvider>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default CaseListTable;
