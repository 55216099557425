import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import ReactBSAlert from 'react-bootstrap-sweetalert';
import { useHistory, useLocation } from 'react-router-dom';
import {
	Button,
	Card,
	Container,
	Row,
	Col,
	UncontrolledTooltip,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from 'reactstrap';

import { handleRedirect, handleOptions } from 'utils/handles';

import Loading from 'components/General/Loading';
import PageHeader from 'components/Headers/PageHeader';
import { formatDate } from 'utils/dateFormatter';
import { titleCase } from 'utils/stringFormatter';
import secureAPI from 'utils/secureAPI';

const pagination = paginationFactory({
	page: 1,
	alwaysShowAllBtns: true,
	showTotal: true,
	withFirstAndLast: false,
	paginationTotalRenderer: (from, to, size) => (
		<span className="react-bootstrap-table-pagination-total">
			Showing {from} to {to} of {size} results
		</span>
	),
	sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
		<div className="dataTables_length" id="datatable-basic_length">
			<label>
				Show
				{
					<select
						name="datatable-basic_length"
						aria-controls="datatable-basic"
						className="form-control form-control-sm"
						onChange={e => onSizePerPageChange(e.target.value)}
					>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="30">30</option>
						<option value="40">40</option>
						<option value="50">50</option>
					</select>
				}
				entries.
			</label>
		</div>
	),
});

const { SearchBar } = Search;

const KnownsList = props => {
	const [alert, setAlert] = useState([]);
	const [dataTable, setDataTable] = useState([]);
	const [loading, setLoading] = useState(true);
	const tableRef = useRef();

	const NoDataIndication = () => (
		<Container fluid className="adapt">
			<Row>
				<Col className="text-center m-6">{loading ? <Loading /> : <div>No data available</div>}</Col>
			</Row>
		</Container>
	);

	let history = useHistory();
	let location = useLocation();

	const optionsFormatter = (cell, row) => {
		const dynamicButton = handleOptions(
			'Matched',
			history,
			row['CLIENTID_COURT'],
			row['ReferralID'],
			row['CurrentCaseStatus'],
			props.baseRoute,
			row['FullName'],
		);

		return props.user.attributes['custom:eji_role'] === 'admin' ? (
			<UncontrolledDropdown>
				<DropdownToggle className="btn-icon-only" size="sm" color="">
					<i className="fas fa-ellipsis-v"></i>
				</DropdownToggle>
				<DropdownMenu className="table-dropdown">
					{dynamicButton}
					<DropdownItem divider />
					<DropdownItem
						onClick={e =>
							handleRedirect(
								e,
								{
									history: history,
									pathid: 'courtprofileupdate',
									baseRoute: props.baseRoute,
									parentName: 'Known Persons List',
									parentUrl: location.pathname,
								},
								['advice', row['CLIENTID_COURT'], row['CLIENTID_COURT']],
							)
						}
					>
						<i className="fas fa-edit" style={{ margin: '0 10px 0 0' }}></i>
						Add Brief Advice
					</DropdownItem>
					<DropdownItem
						onClick={e =>
							handleRedirect(
								e,
								{
									history: history,
									pathid: 'courtprofileupdate',
									baseRoute: props.baseRoute,
									parentName: 'Known Persons List',
									parentUrl: location.pathname,
								},
								['school', row['CLIENTID_COURT'], row['CLIENTID_COURT']],
							)
						}
					>
						<i className="fas fa-school" style={{ margin: '0 10px 0 0' }}></i>
						Add School Information
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown>
		) : (
			<UncontrolledDropdown>
				<DropdownToggle className="btn-icon-only" size="sm" color="">
					<i className="fas fa-ellipsis-v"></i>
				</DropdownToggle>
				{dynamicButton !== null ? (
					<DropdownMenu className="table-dropdown">{dynamicButton}</DropdownMenu>
				) : (
					<DropdownMenu className="table-dropdown">
						<DropdownItem> No Action Available </DropdownItem>
					</DropdownMenu>
				)}
			</UncontrolledDropdown>
		);
	};

	const dateFormatter = (cell, row) => {
		return formatDate(cell);
	};

	useEffect(() => {
		let canSet = true;
		secureAPI({
			endpoint: 'KNOWNS',
			method: 'GET',
		})
			.then(response => {
				if (canSet) {
					response.data.map(el => (el['FullName'] = `${titleCase(el.FirstName)} ${titleCase(el.LastName)}`));
					setDataTable(response.data);
					setLoading(false);
				}
			})
			.catch(error => {
				console.log(error);
			});

		return () => (canSet = false);
	}, []);

	const copyToClipboardAsTable = el => {
		var body = document.body,
			range,
			sel;
		if (document.createRange && window.getSelection) {
			range = document.createRange();
			sel = window.getSelection();
			sel.removeAllRanges();
			try {
				range.selectNodeContents(el);
				sel.addRange(range);
			} catch (e) {
				range.selectNode(el);
				sel.addRange(range);
			}
			document.execCommand('copy');
		} else if (body.createTextRange) {
			range = body.createTextRange();
			range.moveToElementText(el);
			range.select();
			range.execCommand('Copy');
		}
		setAlert(
			<ReactBSAlert
				success
				style={{ display: 'block', marginTop: '0px' }}
				title="Good job!"
				onConfirm={() => setAlert([])}
				onCancel={() => setAlert([])}
				confirmBtnBsStyle="info"
				btnSize=""
			>
				Copied to clipboard!
			</ReactBSAlert>,
		);
	};

	const knownProfileEvent = {
		onClick: (e, col, colIndex, row, rowIndex) =>
			history.push(`${props.baseRoute}/known-persons/${row['CLIENTID_COURT']}`),
	};

	return (
		<>
			{alert}

			<PageHeader name="Known Persons List" parentName="Hub" />
			<Container className="court-list-table mt--6" fluid>
				<Row>
					<div className="col">
						<Card>
							<ToolkitProvider
								data={dataTable}
								keyField="CLIENTID_COURT"
								search={{
									searchFormatted: false,
								}}
								columns={[
									{
										dataField: '',
										text: 'Action',
										classes: 'button-cell',
										sort: false,
										formatter: optionsFormatter,
									},
									{
										dataField: 'CLIENTID_COURT',
										text: 'Young Person ID',
										sort: true,
										events: knownProfileEvent,
									},
									{
										dataField: 'FullName',
										text: 'Name',
										sort: true,
										events: knownProfileEvent,
									},
									{
										dataField: 'DOB',
										text: 'Date of Birth',
										sort: true,
										formatter: dateFormatter,
										events: knownProfileEvent,
									},
									{
										dataField: 'Gender',
										text: 'Gender',
										sort: true,
										events: knownProfileEvent,
									},
									{
										dataField: 'FirstKnown',
										text: 'Date First Known',
										sort: true,
										formatter: dateFormatter,
										events: knownProfileEvent,
									},
									{
										dataField: 'DateEnrolmentUpdated',
										text: 'Date Enrolment Updated',
										sort: true,
										formatter: dateFormatter,
										events: knownProfileEvent,
									},
									{
										dataField: 'CurrentCaseStatus',
										text: 'EJI Case Status',
										hidden: true,
									},
									{
										dataField: 'FirstName',
										text: 'First Name',
										hidden: true,
									},
									{
										dataField: 'LastName',
										text: 'Last Name',
										hidden: true,
									},
								]}
							>
								{props => {
									return (
										<div className="py-4 table-responsive">
											<Container fluid>
												<Row>
													<Col xs={12} sm={6}>
														<Button
															className="buttons-copy buttons-html5"
															color="default"
															size="sm"
															id="copy-tooltip"
															onClick={() =>
																copyToClipboardAsTable(
																	document.getElementById('court-list-table'),
																)
															}
														>
															<span>
																<i
																	className="fas fa-copy"
																	style={{ margin: '0 10px 0 0' }}
																></i>
																Copy
															</span>
														</Button>
														<ReactToPrint
															trigger={() => (
																<Button
																	color="default"
																	size="sm"
																	className="buttons-copy buttons-html5"
																	id="print-tooltip"
																>
																	<span>
																		<i
																			className="fas fa-print"
																			style={{ margin: '0 10px 0 0' }}
																		></i>
																		Print
																	</span>
																</Button>
															)}
															content={() => tableRef.current}
														/>
														<UncontrolledTooltip placement="top" target="print-tooltip">
															This will open a print page with the visible rows of the
															table.
														</UncontrolledTooltip>
														<UncontrolledTooltip placement="top" target="copy-tooltip">
															This will copy to your clipboard the visible rows of the
															table.
														</UncontrolledTooltip>
													</Col>
													<Col xs={12} sm={6}>
														<div
															id="datatable-basic_filter"
															className="dataTables_filter pb-1 float-right"
														>
															<label>
																Search:
																<SearchBar
																	className="form-control-sm"
																	placeholder=""
																	{...props.searchProps}
																/>
															</label>
														</div>
													</Col>
												</Row>
											</Container>
											<BootstrapTable
												id="court-list-table"
												ref={tableRef}
												classes="button-table"
												bootstrap4={true}
												bordered={false}
												pagination={pagination}
												filter={filterFactory()}
												noDataIndication={() => <NoDataIndication />}
												{...props.baseProps}
											/>
										</div>
									);
								}}
							</ToolkitProvider>
						</Card>
					</div>
				</Row>
			</Container>
		</>
	);
};

export default KnownsList;
