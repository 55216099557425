import React from 'react';
import { Card, CardHeader, CardBody, Collapse } from 'reactstrap';
import { formatShortDate, formatDateTime } from 'utils/dateFormatter';

const TimelineCard = props => {
	if (props.header.type === 'collapse') {
		let state = props.header.state;
		let index = props.header.collapseIndex;
		return (
			<Card>
				<TimelineHeader {...props.header} />
				<Collapse isOpen={state.includes(index)}>
					<TimelineBody {...props.body} />
				</Collapse>
			</Card>
		);
	} else {
		return (
			<Card>
				<TimelineHeader {...props.header} />
				<TimelineBody {...props.body} />
			</Card>
		);
	}
};

const TimelineHeader = props => {
	let state = props.state;

	const collapseToggle = collapse => {
		if (state.includes(collapse)) {
			props.stateFunc([]);
		} else {
			props.stateFunc([collapse]);
		}
	};

	if (props.type === 'collapse') {
		return (
			<CardHeader
				className="bg-transparent"
				onClick={() => collapseToggle(props.collapseIndex)}
				aria-expanded={state.includes(props.collapseIndex)}
			>
				<h3 className="mb-0">{props.title}</h3>
			</CardHeader>
		);
	} else {
		return (
			<CardHeader className="bg-transparent">
				<h3 className="mb-0">{props.title}</h3>
			</CardHeader>
		);
	}
};

const TimelineBody = props => {
	let data = props.data.filter(el => el[props.target]);
	return (
		<CardBody>
			{data.length > 0 ? (
				data.map((element, index) => (
					<div
						className="timeline timeline-one-side"
						data-timeline-axis-style="dashed"
						data-timeline-content="axis"
						key={index}
					>
						<div className="timeline-block">
							<span className={props.iconStyle}>
								<i className={props.icon} />
							</span>
							<div className="timeline-content">
								<small className="text-muted font-weight-bold">
									{props.dateType === 'short'
										? formatShortDate(new Date(element[props.date]))
										: formatDateTime(new Date(element[props.date]))}
								</small>
								{element.userEmail ? <h5 className="mt-1 mb-0">{`${element.userEmail}`}</h5> : null}
								<p className="text-sm mt-1 mb-0">{element[props.target]}</p>
							</div>
						</div>
					</div>
				))
			) : (
				<p>{props.nodata}</p>
			)}
		</CardBody>
	);
};

export default TimelineCard;
