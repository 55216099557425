import React, { useEffect, useState } from 'react';
import ReactIframeResizer from 'react-iframe-resizer-super';
import { useParams, useLocation } from 'react-router-dom';
import { Card, Container, Row, Col } from 'reactstrap';
import { titleCase } from 'utils/stringFormatter';

import ProfileHeader from 'components/Headers/ProfileHeader';
import { Endpoints } from 'config/endpoints';
import { iframeOptions, iframeStyles } from 'config/iframes';

const CourtProfileUpdateForm = props => {
	const params = useParams();
    const location = useLocation();
    const [updateUrl, setUpdateUrl] = useState(`${Endpoints.UPDATE_COURT}?UpdateType=${params.type}&ClientID_Court=${params.id}&ClientID_Map=${params.idmap}`);
    
    useEffect(() => {
        let canSet = true
        if (canSet) {
            setUpdateUrl(`${Endpoints.UPDATE_COURT}?UpdateType=${params.type}&ClientID_Court=${params.id}&ClientID_Map=${params.idmap}`);
        };
        
        return () => (canSet = false)
    }, [params.type, params.id, params.idmap]);

	return (
		<>
			<ProfileHeader 
				title="Profile Update" 
				name={titleCase(params.type) + " Form"}
				parentName={location.state.parentName}
				parentLink={location.state.parentUrl}
				baseRoute={props.baseRoute}
			/>


			<Container className="mt--6" fluid>
				<Row>
					<Col>
						<Card style={{ height: '76vh', background: '#F3F3F3' }}>
							<ReactIframeResizer
								iframeResizerOptions={iframeOptions}
								iframeResizerUrl={false}
								src={updateUrl}
								style={iframeStyles}
							></ReactIframeResizer>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default CourtProfileUpdateForm;

