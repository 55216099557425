export const iframeOptions = {
	checkOrigin: false,
	heightCalculationMethod: 'max',
	scrolling: 'true',
	resizeFrom: 'parent',
	minHeight: '700',
	warningTimeout: 0,
};

export const iframeStyles = {
	height: '100%',
	width: '100%',
	overflow: 'auto',
	padding: '20px 0 10px 10px',
};
